import { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Breadcrumb from 'src/components/Breadcrumb';
import Loading from 'src/components/Loading';
import ConfirmModal from 'src/components/Modal/Confirm';
import PageWrapper from 'src/components/Page/PageWrapper';
import ToastAlert from 'src/components/ToastAlert';
import { DEFAULT_LOCATION } from 'src/helper/constants';
import { LOCATION_EMERGENCY_TYPE } from 'src/helper/constants/location';
import {
  ADD_STAFF_SUCCESS_PARAM_KEY,
  ARCHIVE_SUCCESS_PARAM_KEY,
  CALLBACK_ACTION_PARAM_KEY,
  EDIT_SUCCESS_PARAM_KEY,
} from 'src/helper/constants/routes';
import { RESOURCE_DATA_KEY } from 'src/helper/constants/store';
import useOwnNavigate from 'src/helper/hooks/useOwnNavigate';
import { getFullAddress } from 'src/utils/address';
import { notifyPrimary } from 'src/utils/notification';
import {
  getAllUrlParams,
  getRemovedParamFromUrl,
  getViewUrlAction,
} from 'src/utils/routes';
import LocationDetailsInfo from './Info';
import classes from './LocationDetails.module.scss';
import DetailsTab from './Tabs';
import DetailsTitle from './Title';
import AddContactModal from './components/AddContactModal';
import AddPlanModal from './components/AddPlanModal';
import {
  LOCATION_DETAILS_TAB_KEYS,
  LOCATION_DETAILS_TAB_SUB_KEYS,
  LOCATION_TAB_DEFAULT_FILTERS,
  getAddedStaffMessage,
  getArchiveDocumentMessage,
  getDeleteContactObj,
  getDeleteStaffObj,
  getTabItems,
} from './constants';
import { useActions, useIndexData } from './selectorData';
import useFunction from './useFunction';
import { getListFiltersParams } from './utils';

let locationFilters = {
  ...LOCATION_TAB_DEFAULT_FILTERS,
};

const LocationDetails = () => {
  const { onNavigate, fullPathname } = useOwnNavigate();
  const notificationAlertRef = useRef(null);
  const location = useLocation();
  const urlParams = getAllUrlParams(location?.search);
  const params = useParams();
  const locationId = Number(params.id || 0);
  const { getLocationDetails, getResources } = useActions();
  const {
    locationDetails,
    locationDetailsLoading,
    emergencyContactsData,
    emergencyProceduresData,
    emergencyEvacuationPlansData,
    locationStaffsData,
  } = useIndexData();
  const isEmergencyWarning =
    !emergencyProceduresData?.meta?.total ||
    !emergencyEvacuationPlansData?.meta?.total;
  const preActiveTab = urlParams?.tab;
  const [activeTab, setActiveTab] = useState(
    LOCATION_DETAILS_TAB_KEYS[preActiveTab]
      ? preActiveTab
      : LOCATION_DETAILS_TAB_KEYS.SUB_SYSTEMS
  );
  const [markers, setMarkers] = useState([]);
  const [modalContactObj, setModalContactObj] = useState(null);
  const [modalPlanObj, setModalPlanObj] = useState(null);
  const [deleteObj, setDeleteObj] = useState(null);
  const [roleList, setRoleList] = useState([]);
  const breadcrumbs = [
    {
      link: `/locations`,
      label: 'Locations',
    },
    {
      label: locationDetails.name,
    },
  ];
  const notify = (type, title, description) => {
    notifyPrimary({
      ref: notificationAlertRef,
      type,
      title,
      description,
      settings: {
        // autoDismiss: 1000,
      },
    });
  };
  const handleAddContact = () => {
    setModalContactObj({});
  };
  const handleEditContact = (obj) => {
    setModalContactObj(obj);
  };
  const handleDeleteContact = (obj) => {
    setDeleteObj(getDeleteContactObj(obj.id));
  };
  const handleViewPlan = (tType, obj) => {
    onNavigate(`/locations/${locationId}/emergency/${obj.id}`);
  };
  const handleAddPlan = (tType) => {
    setModalPlanObj({ type: tType });
  };
  const handleDeleteStaff = (obj) => {
    setDeleteObj(getDeleteStaffObj(obj.id));
  };
  const loadTabData = (tTabKey, subKey, otherFilters) => {
    switch (tTabKey) {
      case LOCATION_DETAILS_TAB_KEYS.EMERGENCY: {
        switch (subKey) {
          case LOCATION_DETAILS_TAB_SUB_KEYS.CONTACTS:
            getResources(RESOURCE_DATA_KEY.EMERGENCY_CONTACT, {
              page: 1,
              per_page: 9999,
              filters: {
                location_id: locationId,
              },
            });
            break;
          case LOCATION_DETAILS_TAB_SUB_KEYS.PROCEDURES:
            getResources(RESOURCE_DATA_KEY.EMERGENCY_DOCUMENT_PROCEDURES, {
              document_type: 'procedure',
              page: otherFilters?.page,
              per_page: otherFilters?.size,
              location_id: locationId,
            });
            break;
          case LOCATION_DETAILS_TAB_SUB_KEYS.EVACUATION_PLANS:
            getResources(
              RESOURCE_DATA_KEY.EMERGENCY_DOCUMENT_EVACUATION_PLANS,
              {
                document_type: 'evacuation_plan',
                page: otherFilters?.page,
                per_page: otherFilters?.size,
                location_id: locationId,
              }
            );
            break;
          default:
            break;
        }
        break;
      }
      case LOCATION_DETAILS_TAB_KEYS.STAFF_LIST: {
        getResources(
          RESOURCE_DATA_KEY.LOCATION_STAFF,
          getListFiltersParams(otherFilters, locationId)
        );
        break;
      }
      default:
        break;
    }
  };
  const { onConfirmedContact, onConfirmedDelete, onUploadPlan, onReloadRoles } =
    useFunction(locationId, loadTabData, notify, setRoleList);
  const handleEmergencyPaginationChange = (type, tPage, tSize) => {
    if (type === LOCATION_EMERGENCY_TYPE.PROCEDURE) {
      loadTabData(
        LOCATION_DETAILS_TAB_KEYS.EMERGENCY,
        LOCATION_DETAILS_TAB_SUB_KEYS.PROCEDURES,
        { page: tPage, size: tSize }
      );
    } else {
      loadTabData(
        LOCATION_DETAILS_TAB_KEYS.EMERGENCY,
        LOCATION_DETAILS_TAB_SUB_KEYS.EVACUATION_PLANS,
        { page: tPage, size: tSize }
      );
    }
  };
  const handleStaffFiltersChanged = (
    tSearch,
    tSort,
    tFilterIds,
    tPagination
  ) => {
    locationFilters.search = tSearch;
    locationFilters.sort = tSort;
    locationFilters.filters_id = [...tFilterIds];
    locationFilters.pagination = { ...tPagination };
    loadTabData(LOCATION_DETAILS_TAB_KEYS.STAFF_LIST, '', {
      ...locationFilters,
      ...tPagination,
    });
  };
  const tabProps = {
    onAddContact: handleAddContact,
    onEditContact: handleEditContact,
    onDeleteContact: handleDeleteContact,
    onViewPlan: handleViewPlan,
    onAddPlan: handleAddPlan,
    onDeleteStaff: handleDeleteStaff,
    onEditStaff: (obj) => {
      onNavigate(`/locations/${locationId}/staff/create?id=${obj.id}`);
    },
    emergencyData: {
      contacts: emergencyContactsData,
      procedures: emergencyProceduresData,
      evacuationPlans: emergencyEvacuationPlansData,
    },
    onEmergencyPaginationChange: handleEmergencyPaginationChange,
    onAddStaff: () => {
      onNavigate(`/locations/${locationId}/add-staff`);
    },
    roleList,
    onStaffFiltersChanged: handleStaffFiltersChanged,
    staffListData: locationStaffsData,
    onStaffRowClick: (obj) => {
      onNavigate(
        getViewUrlAction(`/locations/${locationId}/staff/create?id=${obj.id}`)
      );
    },
  };
  const items = getTabItems(isEmergencyWarning, tabProps);
  const handleConfirmedContact = (values, cb) => {
    onConfirmedContact(values, cb, () => {
      setModalContactObj(null);
    });
  };
  const handleConfirmedDelete = (cb) => {
    onConfirmedDelete(
      deleteObj.type,
      deleteObj.id,
      {
        user_id: deleteObj.id,
      },
      cb,
      () => {
        setDeleteObj(null);
      }
    );
  };
  const handleConfirmedPlan = (values, cb) => {
    onUploadPlan({ ...values, type: modalPlanObj.type }, cb, () => {
      setModalPlanObj(null);
    });
  };

  useEffect(() => {
    locationFilters = {
      ...LOCATION_TAB_DEFAULT_FILTERS,
    };
    getLocationDetails(locationId, (res) => {
      const tMarket = {
        id: 2,
        name: '',
      };
      if (res) {
        const tAddress = getFullAddress(res?.data?.address);
        const geocoder = new window.google.maps.Geocoder();
        geocoder.geocode({ address: tAddress }, (results, status) => {
          if (status === 'OK' && results[0]) {
            const location = results[0].geometry.location;
            tMarket.name = tAddress;
            tMarket.position = { lat: location.lat(), lng: location.lng() };
          } else {
            tMarket.position = { ...DEFAULT_LOCATION };
            console.error('Geocode error:', status);
          }
          setMarkers([tMarket]);
        });
      } else {
        tMarket.position = { ...DEFAULT_LOCATION };
        setMarkers([tMarket]);
      }
    });
    loadTabData(activeTab, LOCATION_DETAILS_TAB_SUB_KEYS.CONTACTS, {
      page: 1,
      size: 10,
    });
    loadTabData(
      LOCATION_DETAILS_TAB_KEYS.EMERGENCY,
      LOCATION_DETAILS_TAB_SUB_KEYS.PROCEDURES,
      { page: 1, size: 4 }
    );
    loadTabData(
      LOCATION_DETAILS_TAB_KEYS.EMERGENCY,
      LOCATION_DETAILS_TAB_SUB_KEYS.EVACUATION_PLANS,
      { page: 1, size: 4 }
    );
    onReloadRoles();
    if (
      !!urlParams?.[CALLBACK_ACTION_PARAM_KEY] &&
      [
        ARCHIVE_SUCCESS_PARAM_KEY,
        ADD_STAFF_SUCCESS_PARAM_KEY,
        EDIT_SUCCESS_PARAM_KEY,
      ].includes(urlParams?.[CALLBACK_ACTION_PARAM_KEY])
    ) {
      if (
        urlParams?.[CALLBACK_ACTION_PARAM_KEY] === ARCHIVE_SUCCESS_PARAM_KEY
      ) {
        notify(...getArchiveDocumentMessage());
      } else {
        notify(
          ...getAddedStaffMessage(
            urlParams?.[CALLBACK_ACTION_PARAM_KEY] === EDIT_SUCCESS_PARAM_KEY
          )
        );
      }
      onNavigate(
        getRemovedParamFromUrl(fullPathname, [CALLBACK_ACTION_PARAM_KEY])
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <PageWrapper className={classes.wrapper}>
        {locationDetailsLoading && <Loading isPage />}
        <Breadcrumb items={breadcrumbs} />
        <DetailsTitle />
        <LocationDetailsInfo markers={markers} />
        <DetailsTab
          items={items}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          loadTabData={loadTabData}
        />
      </PageWrapper>
      {!!modalContactObj && (
        <AddContactModal
          isOpen
          onClose={() => {
            setModalContactObj(null);
          }}
          onSubmit={handleConfirmedContact}
          editObj={modalContactObj}
        />
      )}
      {!!modalPlanObj && (
        <AddPlanModal
          isOpen
          onClose={() => {
            setModalPlanObj(null);
          }}
          onSubmit={handleConfirmedPlan}
          obj={modalPlanObj}
        />
      )}
      {!!deleteObj && (
        <ConfirmModal
          isOpen
          onClose={() => {
            setDeleteObj(null);
          }}
          onSubmit={handleConfirmedDelete}
          title={deleteObj?.title}
          submitText={deleteObj?.submitText}
          size="sm"
        >
          {deleteObj?.description}
        </ConfirmModal>
      )}
      <ToastAlert toastRef={notificationAlertRef} />
    </>
  );
};

export default LocationDetails;
