import { ReactComponent as SettingsSvg } from 'src/assets/icons/Nav/Settings1.svg';
import { ReactComponent as SettingsActiveSvg } from 'src/assets/icons/Nav/Settings1_Active.svg';
import Executions from 'src/pages/Executions';

const executionsRoutes = [
  {
    path: '/executions/incident-investigation',
    name: ' - Incident Investigation',
    icon: <SettingsSvg />,
    iconActive: <SettingsActiveSvg />,
    component: Executions,
    layout: 'private',
  },
  {
    path: '/executions/example-test',
    name: ' - Example Test',
    icon: <SettingsSvg />,
    iconActive: <SettingsActiveSvg />,
    component: Executions,
    layout: 'private',
  },
  {
    path: '/executions/:id',
    name: 'Executions',
    icon: '',
    iconActive: '',
    component: Executions,
    layout: 'private',
  },
  // {
  //   path: '/executions/edit/:id',
  //   name: 'Edit Execution',
  //   icon: '',
  //   iconActive: '',
  //   component: CreateExecution,
  //   layout: 'private',
  // },
  // {
  //   path: '/executions/create-execution',
  //   name: 'Create Execution',
  //   icon: '',
  //   iconActive: '',
  //   component: CreateExecution,
  //   layout: 'private',
  // },
];

export default executionsRoutes;
