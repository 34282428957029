import cn from 'classnames';
import { ReactComponent as WarningSvg } from 'src/assets/icons/warning-2.svg';
import Tag from 'src/components/Tag';
import classes from './Emergency.module.scss';

const EmergencyEmptyPlan = ({ className }) => {
  return (
    <div className={cn(classes.emergencyEmptyPlan, className)}>
      <div>
        <WarningSvg />
        <Tag variant="red" weight="lg">
          Action Required
        </Tag>
        <span>
          At least 1 procedure document
          <br />
          needs be added for compliance.
        </span>
      </div>
    </div>
  );
};

export default EmergencyEmptyPlan;
