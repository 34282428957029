import React, { createContext, useContext, useEffect, useState } from 'react';
import { LOCATIONS_SCOPE_ATTR_NAME } from 'src/helper/constants';
import { RESOURCE_DATA_KEY } from 'src/helper/constants/store';
import useDynamicMenu from 'src/helper/hooks/useDynamicMenu';
import useResourceActions from 'src/helper/hooks/useResourceActions';
import { MenuContext } from 'src/helper/providers/MenuProvider';
import * as menuActions from 'src/store/actions/menu';
import * as sharedActions from 'src/store/actions/shared';
import { useReducerData, useStoreActions } from 'src/store/hooks';
import { applySearch } from 'src/utils/search';
import {
  getAllMenusFromLocations,
  getLocationOptionFromId,
  getSelectedLocationIds,
} from '../utils';

const useActions = () => {
  return useStoreActions({
    ...menuActions,
    ...sharedActions,
  });
};

const MetadataContext = createContext({
  roleOptions: [],
  fetchRoles: () => {},
  rolesLoading: false,
  userOptions: [],
  usersLoading: false,
  fetchUsers: () => {},
});

const MetadataProvider = ({ children }) => {
  const { selectedLocationIds } = useContext(MenuContext);
  const selectedLocationIdsString = selectedLocationIds.join(',');
  const { onGetResources } = useResourceActions();
  const [rolesLoading, setRolesLoading] = useState(false);
  const [usersLoading, setUsersLoading] = useState(false);
  const [roleOptions, setRoleOptions] = useState([]);
  const [userOptions, setUserOptions] = useState([]);
  const isLoggedIn = !!useReducerData('auth', 'login.data.token', '');

  const onReloadRoles = async () => {
    setRolesLoading(true);
    const res = await onGetResources(RESOURCE_DATA_KEY.ROLES, {
      page: 1,
      per_page: 99999,
      [LOCATIONS_SCOPE_ATTR_NAME]: selectedLocationIdsString,
    });
    const tRoles = res?.isSuccess ? res.data : [];
    setRoleOptions(
      tRoles.map((obj) => ({
        value: String(obj.id),
        label: obj.role_name,
      }))
    );
    setRolesLoading(false);
  };
  const onReloadUsers = async () => {
    setUsersLoading(true);
    const res = await onGetResources(RESOURCE_DATA_KEY.USERS, {
      page: 1,
      per_page: 99999,
      [LOCATIONS_SCOPE_ATTR_NAME]: selectedLocationIdsString,
    });
    setUsersLoading(false);
    setUserOptions(
      (res?.isSuccess ? res.data : []).map((obj) => ({
        value: String(obj.id),
        label: obj.name,
      }))
    );
  };

  useEffect(() => {
    if (isLoggedIn && selectedLocationIdsString) {
      onReloadRoles();
      onReloadUsers();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn, selectedLocationIdsString]);

  return (
    <MetadataContext.Provider
      value={{
        roleOptions,
        fetchRoles: onReloadRoles,
        rolesLoading,
        userOptions,
        usersLoading,
        fetchUsers: onReloadUsers,
      }}
    >
      {children}
    </MetadataContext.Provider>
  );
};
export const useMetadata = () => useContext(MetadataContext);

export { MetadataContext, MetadataProvider };
