import { ReactComponent as DashboardSvg } from 'src/assets/icons/Nav/Dashboard.svg';
import { ReactComponent as DashboardActiveSvg } from 'src/assets/icons/Nav/Dashboard_Active.svg';
import { ReactComponent as SettingsSvg } from 'src/assets/icons/Nav/Settings1.svg';
import { ReactComponent as SettingsActiveSvg } from 'src/assets/icons/Nav/Settings1_Active.svg';
import Repository from 'src/pages/Repository';
import DocumentCreate from 'src/pages/Repository/DocumentCreate';

const repositoryRoutes = [
  {
    path: '/repository',
    name: 'Repository',
    icon: <DashboardSvg />,
    iconActive: <DashboardActiveSvg />,
    component: Repository,
    layout: 'private',
  },
  {
    path: '/repository/*',
    name: 'Repository',
    icon: <DashboardSvg />,
    iconActive: <DashboardActiveSvg />,
    component: Repository,
    layout: 'private',
  },
  {
    path: '/repository/create',
    name: 'Create Repository Item',
    icon: <SettingsSvg />,
    iconActive: <SettingsActiveSvg />,
    component: DocumentCreate,
    layout: 'private',
  },
  {
    path: '/repository/edit',
    name: 'Edit Repository Item',
    icon: <SettingsSvg />,
    iconActive: <SettingsActiveSvg />,
    component: DocumentCreate,
    layout: 'private',
  },
  {
    path: '/repository/view',
    name: 'View Repository Item',
    icon: <SettingsSvg />,
    iconActive: <SettingsActiveSvg />,
    component: DocumentCreate,
    layout: 'private',
  },
];

export default repositoryRoutes;
