import { Controller } from 'react-hook-form';
import Input from './';

const FormInput = ({ name, control, onChange, ...props }) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <Input
          {...field}
          {...props}
          error={error?.message}
          onChange={(e) => {
            field.onChange(e.target.value);
            if (onChange) {
              onChange(e);
            }
          }}
        />
      )}
    />
  );
};

export default FormInput;
