import cn from 'classnames';
import Button from 'src/components/Button';
import { LOCATION_EMERGENCY_TYPE } from 'src/helper/constants/location';
import useProfile from 'src/helper/hooks/useProfile';
import EmergencyContact from './Contact';
import classes from './Emergency.module.scss';
import EmergencyPlan from './Plan';

const Emergency = ({
  className,
  onAddContact,
  onEditContact,
  onDeleteContact,
  onViewPlan,
  onAddPlan,
  data,
  onPaginationChange,
}) => {
  const currentUser = useProfile();
  return (
    <div className={cn(classes.wrapper, className)}>
      <div className={classes.content}>
        <div className={classes.plans}>
          <EmergencyPlan
            title="Procedures"
            btnText="Add New"
            items={data?.procedures?.data || []}
            meta={data?.procedures?.meta}
            onViewPlan={(obj) => {
              if (onViewPlan)
                onViewPlan(LOCATION_EMERGENCY_TYPE.PROCEDURE, obj);
            }}
            onAddPlan={() => {
              if (onAddPlan) onAddPlan(LOCATION_EMERGENCY_TYPE.PROCEDURE);
            }}
            isNotFound={!data?.procedures?.meta?.total}
            onPaginationChange={(...params) => {
              if (onPaginationChange)
                onPaginationChange(
                  LOCATION_EMERGENCY_TYPE.PROCEDURE,
                  ...params
                );
            }}
          />
          <EmergencyPlan
            title="Evacuation Plans"
            btnText="Add New"
            items={data?.evacuationPlans?.data || []}
            meta={data?.evacuationPlans?.meta}
            onViewPlan={(obj) => {
              if (onViewPlan)
                onViewPlan(LOCATION_EMERGENCY_TYPE.EVACUATION_PLAN, obj);
            }}
            onAddPlan={() => {
              if (onAddPlan) onAddPlan(LOCATION_EMERGENCY_TYPE.EVACUATION_PLAN);
            }}
            isNotFound={!data?.evacuationPlans?.meta?.total}
            onPaginationChange={(...params) => {
              if (onPaginationChange)
                onPaginationChange(
                  LOCATION_EMERGENCY_TYPE.EVACUATION_PLAN,
                  ...params
                );
            }}
          />
        </div>
        <div className={classes.contactsWrapper}>
          <div className={classes.header}>
            <span>Contacts</span>
            <div>
              <Button
                variant="primary"
                onClick={onAddContact}
                fontSize="sm"
                weight="lg"
                size="medium"
                isLink
              >
                Add New Contact
              </Button>
            </div>
          </div>
          {data?.contacts?.data?.length > 0 && (
            <div className={classes.contacts}>
              {data.contacts.data.map((contact) => {
                return (
                  <EmergencyContact
                    key={contact.id}
                    data={contact}
                    onEdit={() => {
                      onEditContact(contact);
                    }}
                    onDelete={() => {
                      onDeleteContact(contact);
                    }}
                    isManual={contact.created_by === currentUser.id}
                  />
                );
              })}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Emergency;
