import cn from 'classnames';
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { ReactComponent as DashboardSvg } from 'src/assets/icons/Nav/Dashboard.svg';
import { ReactComponent as DashboardActiveSvg } from 'src/assets/icons/Nav/Dashboard_Active.svg';
import EventProvider from 'src/components/Calendar/contexts/EventContext.js';
import Sidebar from 'src/components/Sidebar';
import Topbar from 'src/components/Topbar';
import useSidebarMenus from 'src/helper/hooks/useSidebarMenus';
import routes from 'src/routes';
import {
  collapseSidebar,
  expandSidebar,
  isSidebarExpanded,
} from 'src/utils/nav';
import classes from './Main.module.scss';
import { useActions } from './selectorData';

const ALWAYS_MENUS = [
  {
    name: 'Dashboard',
    path: '/dashboard',
    icon: <DashboardSvg />,
    iconActive: <DashboardActiveSvg />,
  },
];

const MainLayout = ({ children }) => {
  const { menus } = useSidebarMenus();
  const { logOutUser } = useActions();
  const [sidenavOpen, setSidenavOpen] = React.useState(true);
  const location = useLocation();
  const pathname = location.pathname;
  const mainContentRef = React.useRef(null);
  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainContentRef.current.scrollTop = 0;
  }, [location]);
  const getBrandText = () => {
    for (let i = 0; i < routes.length; i++) {
      if (pathname.indexOf(routes[i].layout + routes[i].path) !== -1) {
        return routes[i].name;
      }
    }
    return 'Brand';
  };
  // toggles collapse between mini sidenav and normal
  const toggleSidenav = () => {
    if (isSidebarExpanded()) {
      collapseSidebar();
    } else {
      expandSidebar();
    }
    setSidenavOpen(!sidenavOpen);
  };
  const getNavbarTheme = () => {
    return pathname.indexOf('admin/alternative-dashboard') === -1
      ? 'dark'
      : 'light';
  };

  useEffect(() => {
    setTimeout(() => {
      const el = document.getElementById('root');
      if (el) el.scrollTo({ top: 0, behavior: 'smooth' });
    }, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);
  useEffect(() => {
    setTimeout(() => {
      if (window?.innerWidth < 1200) {
        if (isSidebarExpanded()) {
          collapseSidebar();
          setSidenavOpen(false);
        }
      }
    }, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <EventProvider>
        <Sidebar
          routes={[...ALWAYS_MENUS, ...menus]}
          toggleSidenav={toggleSidenav}
          sidenavOpen={sidenavOpen}
          logo={{
            innerLink: '/',
            imgSrc: require('src/assets/img/brand/argon-react.png'),
            imgAlt: '...',
          }}
        />
        <div
          className={cn('main-content', classes.wrapper)}
          ref={mainContentRef}
        >
          <Topbar
            theme={getNavbarTheme()}
            toggleSidenav={toggleSidenav}
            sidenavOpen={sidenavOpen}
            brandText={getBrandText(pathname)}
            onLogout={() => {
              logOutUser();
            }}
          />
          <div className={classes.childContent}>{children}</div>
        </div>
        {sidenavOpen ? (
          <div
            className={cn('backdrop d-xl-none', classes.backdrop)}
            onClick={toggleSidenav}
          />
        ) : null}
      </EventProvider>
    </>
  );
};

export default MainLayout;
