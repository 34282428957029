import cn from 'classnames';
import React, { useRef } from 'react';
import { UncontrolledTooltip } from 'reactstrap';
import classes from './Tooltip.module.scss';

const Tooltip = (props) => {
  const tooltipRef = useRef(null);
  const { children, tooltipText, className, dark, hasArrow, ...other } = props;

  return (
    <>
      <span ref={tooltipRef} className={cn(classes.wrapper, className)}>
        {children}
      </span>
      <UncontrolledTooltip
        delay={0}
        {...other}
        target={tooltipRef}
        placementPrefix={`${dark && 'tooltip-overlay-dark'} ${
          hasArrow && 'tooltip-has-arrow'
        } bs-tooltip`}
      >
        {tooltipText}
      </UncontrolledTooltip>
    </>
  );
};

export default Tooltip;
