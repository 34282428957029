import { useContext, useState } from 'react';
import { ReactComponent as PresentionChartSvg } from 'src/assets/icons/presention-chart.svg';
import FieldsRow from 'src/components/Form/FieldsRow';
import Tabs from 'src/components/Tabs';
import StatusTag from 'src/components/Tag/Status';
import { MenuContext } from 'src/helper/providers/MenuProvider';
import { getRealLocationName } from 'src/helper/providers/utils';
import Field from 'src/pages/GenericCreate/components/Field';
import classes from './View.module.scss';

const EmptyTab = ({ children }) => {
  return (
    <div className={classes.emptyTab}>
      <span>
        <PresentionChartSvg />
      </span>
      <span>{children}</span>
    </div>
  );
};

const CreateStaffView = ({ userDetails }) => {
  const { getSingleLocationOption } = useContext(MenuContext);
  const [activeTab, setActiveTab] = useState('trainings');
  const items = [
    {
      key: 'trainings',
      title: 'Trainings',
      content: (
        <>
          <EmptyTab>
            Once updated, this persons trainings
            <br />
            will appear here
          </EmptyTab>
        </>
      ),
    },
    {
      key: 'certifications',
      title: 'Certifications',
      content: (
        <>
          <EmptyTab>
            Once updated, this persons certifications
            <br />
            will appear here
          </EmptyTab>
        </>
      ),
    },
    {
      key: 'activity',
      title: 'Activity',
      content: (
        <>
          <EmptyTab>
            Once updated, this persons activities
            <br />
            will appear here
          </EmptyTab>
        </>
      ),
    },
  ];
  return (
    <>
      <FieldsRow col={2}>
        <Field label="Name" value={userDetails?.name} bold />
        <Field label="Role" value={userDetails?.role_name} bold />
      </FieldsRow>
      <FieldsRow col={2}>
        <Field label="Phone" value={userDetails?.phone} bold />
        <Field label="Email" value={userDetails?.email} bold />
      </FieldsRow>
      <FieldsRow col={1}>
        <Field
          label="Location"
          value={
            <>
              {(userDetails?.location_ids || [])
                .map((tId) =>
                  getRealLocationName(getSingleLocationOption(tId)?.label || '')
                )
                .filter((v) => !!v)
                .join(', ')}
            </>
          }
          bold
        />
      </FieldsRow>
      <FieldsRow col={2}>
        <Field
          label="Immediate supervisor"
          value={userDetails?.supervisor_name}
          style={{ textDecoration: 'underline' }}
          bold
        />
        <Field
          label="Status"
          bold
          value={
            <span>
              <StatusTag value={userDetails?.status} />
            </span>
          }
        />
      </FieldsRow>
      <Tabs
        items={items}
        activeTab={activeTab}
        onChange={(val) => {
          setActiveTab(val);
        }}
        headerFullWidth
        headerSize="sm"
      />
    </>
  );
};

export default CreateStaffView;
