import cn from 'classnames';
import range from 'lodash/range';
import { useState } from 'react';
import Button from 'src/components/Button';
import Filter from 'src/components/Filter';
import SearchInput from 'src/components/FormFields/Input/SearchInput';
import Pagination from 'src/components/Pagination';
import Table from 'src/components/Table';
import { applySearch } from 'src/utils/search';
import classes from './LocationDetailsTable.module.scss';

const LocationDetailsTable = ({
  className,
  columns,
  title,
  searchPlaceholder,
  addText,
  onAdd,
  filterItems,
  onAllFiltersChanged,
  data,
  onRowClick,
}) => {
  const listData = data?.data || [];
  const listMeta = data?.meta || {};
  const page = listMeta.current_page || 1;
  const size = listMeta.per_page || 10;
  const [search, setSearch] = useState('');
  const [checkedRows, setCheckedRows] = useState([]);
  const [sort, setSort] = useState({
    field: '',
    method: '',
  });
  const [filtersChecked, setFiltersChecked] = useState([]);
  const handleAllFiltersChanged = (...params) => {
    if (onAllFiltersChanged) onAllFiltersChanged(...params);
  };
  const handleFiltersChanged = (checkedIds) => {
    setFiltersChecked(checkedIds);
    handleAllFiltersChanged(search, sort, checkedIds, { page, size });
  };
  return (
    <div className={cn(classes.wrapper, className)}>
      <div className={classes.header}>
        <span>{title}</span>
        <div>
          <SearchInput
            size="medium"
            placeholder={searchPlaceholder}
            iconPosition="right"
            value={search}
            onChange={(e) => {
              setSearch(e.target.value);
              applySearch(e.target.value, (val) => {
                handleAllFiltersChanged(val, sort, filtersChecked, {
                  page,
                  size,
                });
              });
            }}
            light
          />
          <Filter
            items={filterItems}
            onChange={handleFiltersChanged}
            selectedItemIds={filtersChecked}
          />
          <Button
            variant="primary"
            onClick={onAdd}
            fontSize="sm"
            size="medium"
            weight="md"
          >
            {addText || 'Add New'}
          </Button>
        </div>
      </div>
      <Table
        columns={columns}
        data={listData}
        checkable={{
          checkedRows,
          onChange: (cRows) => {
            setCheckedRows(cRows);
          },
        }}
        sort={sort}
        onSortChange={(tField, tMethod) => {
          const newSort = {
            field: tField,
            method: tMethod,
          };
          setSort(newSort);
          handleAllFiltersChanged(search, newSort, filtersChecked, {
            page,
            size,
          });
        }}
        onRowClick={onRowClick}
      />
      <Pagination
        light
        data={{ size, page, total: listMeta.total || 0 }}
        onChange={(tPage, tSize) => {
          if (tPage !== page || tSize !== size) {
            handleAllFiltersChanged(search, sort, filtersChecked, {
              page: tPage,
              size: tSize,
            });
          }
        }}
        disabled={false}
      />
    </div>
  );
};

export default LocationDetailsTable;
