import { useContext, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ReactComponent as ArrowDownSvg } from 'src/assets/icons/arrow-down.svg';
import Breadcrumb from 'src/components/Breadcrumb';
import Button from 'src/components/Button';
import PageTitle from 'src/components/Page/PageTitle';
import PageWrapper from 'src/components/Page/PageWrapper';
import ToastAlert from 'src/components/ToastAlert';
import { LOCATIONS_SCOPE_ATTR_NAME } from 'src/helper/constants';
import { REGION_MENU_NAME } from 'src/helper/constants/menu';
import {
  CALLBACK_ACTION_PARAM_KEY,
  CREATE_SUCCESS_PARAM_KEY,
  EDIT_SUCCESS_PARAM_KEY,
} from 'src/helper/constants/routes';
import useInfiniteScroll from 'src/helper/hooks/useInfiniteScroll';
import useOwnNavigate from 'src/helper/hooks/useOwnNavigate';
import { MenuContext } from 'src/helper/providers/MenuProvider';
import { actionCb } from 'src/utils/actions';
import { notifyPrimary } from 'src/utils/notification';
import { getAllUrlParams } from 'src/utils/routes';
import RegionsContent from './Content';
import classes from './Regions.module.scss';
import { useActions, useIndexData } from './selectorData';

let regionsPage = 1;

const Regions = () => {
  const { availableMenus, selectedLocationIds } = useContext(MenuContext);
  const selectedLocationIdsString = selectedLocationIds.join(',');
  const regionMenuObj = availableMenus.find(
    (obj) => obj.name === REGION_MENU_NAME
  );
  const location = useLocation();
  const urlParams = getAllUrlParams(location?.search);
  const notificationAlertRef = useRef(null);
  const { onNavigate } = useOwnNavigate();
  const [isLazyLoading, setLazyLoading] = useState(false);
  const { settings, regions } = useIndexData();
  const regionsMeta = regions.meta || null;
  const { deleteRegion, getRegions, syncRemoveRegions } = useActions();
  // const isEnableRegions = !!settings.find((obj) => obj.key === 'enable_regions')
  //   ?.value;
  const isEnableRegions = true;
  const breadcrumbs = [
    {
      label: 'Regions',
    },
  ];
  const notify = (type, title, description, settings) => {
    notifyPrimary({
      ref: notificationAlertRef,
      type,
      title,
      description,
      settings,
    });
  };
  const reloadRegions = (locationIdsString, cb) => {
    if (locationIdsString) {
      getRegions(
        {
          page: regionsPage,
          per_page: 10,
          [LOCATIONS_SCOPE_ATTR_NAME]: locationIdsString,
        },
        cb
      );
    }
  };
  const handleDeleteRegion = (deleteId, cb, onClose) => {
    deleteRegion(
      deleteId,
      actionCb(
        () => {
          syncRemoveRegions(deleteId);
          notify('success', 'Success', 'Region deleted successfully');
          if (cb) cb();
          onClose();
        },
        () => {
          notify('error', 'Failed', 'Region deleting failed');
          if (cb) cb();
        }
      )
    );
  };
  const loadMore = async () => {
    setLazyLoading(true);
    regionsPage = regionsMeta.current_page + 1;
    reloadRegions(selectedLocationIdsString, () => {
      setLazyLoading(false);
    });
  };
  useInfiniteScroll({
    elementId: 'root',
    onLoadMore: loadMore,
    shouldLoadMore:
      !isLazyLoading &&
      regionsMeta &&
      regionsMeta.current_page < regionsMeta.last_page,
  });

  useEffect(() => {
    regionsPage = 1;
    if (
      !!urlParams?.[CALLBACK_ACTION_PARAM_KEY] &&
      [CREATE_SUCCESS_PARAM_KEY, EDIT_SUCCESS_PARAM_KEY].includes(
        urlParams[CALLBACK_ACTION_PARAM_KEY]
      )
    ) {
      notify(
        'success',
        'Success!',
        `Region ${
          urlParams[CALLBACK_ACTION_PARAM_KEY] === CREATE_SUCCESS_PARAM_KEY
            ? 'created'
            : 'updated'
        } successfully`
      );
      onNavigate('/regions');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (selectedLocationIdsString) {
      reloadRegions(selectedLocationIdsString);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLocationIdsString]);

  return (
    <>
      <PageWrapper className={classes.wrapper} gap="sm">
        <Breadcrumb items={breadcrumbs} />
        <PageTitle
          gap="sm"
          subTitle={
            <span>Select a Region to view details or make adjustments</span>
          }
        >
          <span>Regions</span>
          <div className={classes.titleButtons}>
            <Button
              variant="primary"
              onClick={() => {
                onNavigate('/regions/create-region');
              }}
              type="button"
              fontSize="sm"
              weight="md"
            >
              Add New
            </Button>
          </div>
        </PageTitle>
        <RegionsContent
          isEnableRegions={isEnableRegions}
          onDelete={handleDeleteRegion}
          menuObj={regionMenuObj}
        />
      </PageWrapper>
      <ToastAlert toastRef={notificationAlertRef} />
    </>
  );
};

export default Regions;
