export const RESOURCE_DATA_KEY = {
  REGIONS: 'regions',
  COMPANIES: 'companies',
  USERS: 'users',
  ROLES: 'roles',
  SUB_SYSTEMS: 'sub-systems',
  PARTS: 'parts',
  LOCATIONS: 'locations',
  LOCATION_STAFF: 'location-staff',
  EMERGENCY_CONTACT: 'emergency-contacts',
  EMERGENCY_DOCUMENT: 'emergency-documents',
  EMERGENCY_DOCUMENT_PROCEDURES: 'emergency-documents-procedures',
  EMERGENCY_DOCUMENT_EVACUATION_PLANS: 'emergency-documents-evacuation-plans',
};
export const RESOURCE_DATA_KEY_MAPPING = {
  [RESOURCE_DATA_KEY.EMERGENCY_DOCUMENT_PROCEDURES]:
    RESOURCE_DATA_KEY.EMERGENCY_DOCUMENT,
  [RESOURCE_DATA_KEY.EMERGENCY_DOCUMENT_EVACUATION_PLANS]:
    RESOURCE_DATA_KEY.EMERGENCY_DOCUMENT,
  [RESOURCE_DATA_KEY.LOCATION_STAFF]: RESOURCE_DATA_KEY.USERS,
};
