import cn from 'classnames';
import { TAG_STATUS_COLOR_MAPPING } from 'src/helper/constants/tag';
import classes from './Tag.module.scss';

const Tag = ({ className, children, variant, weight }) => {
  return (
    <span
      className={cn(
        classes.wrapper,
        classes[`variant_${TAG_STATUS_COLOR_MAPPING[variant] || variant}`],
        classes[`weight_${weight}`],
        className
      )}
    >
      <span>{children}</span>
    </span>
  );
};

export default Tag;
