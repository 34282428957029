import { useContext, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Breadcrumb from 'src/components/Breadcrumb';
import Button from 'src/components/Button';
import FormGroup from 'src/components/FormFields/FormGroup';
import ReSelect from 'src/components/FormFields/Select';
import FormSelect from 'src/components/FormFields/Select/FormSelect';
import Loading from 'src/components/Loading';
import PageWrapper from 'src/components/Page/PageWrapper';
import Tabs from 'src/components/Tabs';
import ToastAlert from 'src/components/ToastAlert';
import useOwnNavigate from 'src/helper/hooks/useOwnNavigate';
import useCurrentPath from 'src/helper/hooks/userCurrentPath';
import { MenuContext } from 'src/helper/providers/MenuProvider';
import FlowRun from 'src/pages/Workflow/components/FlowRun/FlowRun';

import { useReducerData } from 'src/store/hooks';
import { notifyPrimary } from 'src/utils/notification';
import classes from './ExecuteWorkflow.module.scss';
import InformationForm from './components/InformationForm';
import { EXECUTION_TAB_KEYS } from './constants';
import { useActions } from './selectorData';

const ExecuteWorkflow = () => {
  const params = useParams();
  const workflowId = String(params.id || '');
  const executionId = String(params.executionId || '');
  const { onNavigate } = useOwnNavigate();
  const notificationAlertRef = useRef(null);
  const {
    getExecutionDetails,
    createExecution,
    updateExecution,
    getWorkflowDetails,
  } = useActions();
  const [detailsLoading, setDetailsLoading] = useState(false);
  const [activeTab, setActiveTab] = useState(
    executionId ? EXECUTION_TAB_KEYS.EXECUTION : EXECUTION_TAB_KEYS.INFORMATION
  );
  const [execution, setExecution] = useState();
  const [documents, setDocuments] = useState([]);
  const [workflow, setWorkflow] = useState({ nodes: [], edges: [] });
  const [missing, setMissing] = useState(false);
  const [selectLocationOptions, setSelectLocationOptions] = useState([]);
  const [singleLocationId, setSingleLocationId] = useState();
  const location = useLocation();
  const { docData } = location.state || {}; // Safely access state
  const { availableMenus, selectedLocationIds } = useContext(MenuContext);
  const menusData = useReducerData('auth', 'login.data.menus_data', []);

  useEffect(() => {
    // see if we have a single location Id

    if (execution && execution.locationId) {
      // execution started and location Id is known

      if (singleLocationId != execution.locationId)
        setSingleLocationId(execution.locationId);
    } else if (docData && docData.locationIds?.length === 1) {
      // single location Id from document

      if (singleLocationId != docData.locationIds[0])
        setSingleLocationId(docData.locationIds[0]);
    } else if (selectedLocationIds.length == 1) {
      // single location selected in scope filter

      if (singleLocationId != selectedLocationIds[0])
        setSingleLocationId(selectedLocationIds[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLocationIds, execution]);

  useEffect(() => {
    if (!singleLocationId) {
      // we need to ask for specific location
      // calculate the options

      if (docData && docData.locationIds) {
        // suggest from doc locations

        setSelectLocationOptions(docData.form.locationIds);
      } else {
        // suggest from scope filter

        const scopeFilteredMenus = [];
        menusData.forEach((company) => {
          company.regions.forEach((region) => {
            region.locations.forEach((location) => {
              // TODO: check for pemissions
              // const menus = getLocationMenus(location);
              scopeFilteredMenus.push({
                value: location.id,
                label: `${company.name} ➡ ${region.name} ➡ ${location.name}`,
              });
            });
          });
        });

        setSelectLocationOptions(scopeFilteredMenus);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLocationIds, singleLocationId]);

  const informExecutionChanged = (runData, missing) => {
    setMissing(missing);
    setExecution({ ...execution, runData });
  };

  const breadcrumbs = [
    {
      label: 'Executions',
    },
    {
      label: workflowId,
    },
  ];

  const saveExecution = (status, cb) => {
    if (executionId) {
      setDetailsLoading(true);
      var _execution = { ...execution };
      for (const key in _execution.runData) {
        if (key.endsWith('_question_def')) {
          delete _execution.runData[key];
        }
      }

      updateExecution({ ..._execution, status, slug: executionId }, (res) => {
        // eslint-disable-next-line no-console
        console.log(res);
        setExecution({ ...execution, status, slug: executionId });
        setDetailsLoading(false);
        if (cb) cb(res);
      });
    }
  };

  const onStartClick = () => {
    setDetailsLoading(true);
    if (executionId) {
      saveExecution('Started', () => {
        onNavigate(`/workflows/execute/${workflowId}/${executionId}`);
        setActiveTab(EXECUTION_TAB_KEYS.EXECUTION);
      });
    } else {
      const payload = { execution, workflowId, status: 'Started' };
      createExecution(
        { ...payload, slug: workflowId, locationId: singleLocationId },
        (res) => {
          // eslint-disable-next-line no-console
          console.log(res);
          setDetailsLoading(false);
          if (res) {
            onNavigate(`/workflows/execute/${workflowId}/${res._id}`);
          }
        }
      );
    }
  };

  const INFORMATION_TAB = {
    key: EXECUTION_TAB_KEYS.INFORMATION,
    title: 'Information',
    content: (
      <>
        <InformationForm
          onStartClick={onStartClick}
          executionId={executionId}
          execution={execution}
          documents={documents}
          workflow={workflow}
          setActiveTab={setActiveTab}
        />
      </>
    ),
  };

  const EXECUTION_TAB = {
    key: EXECUTION_TAB_KEYS.EXECUTION,
    title: 'Execute',
    content: (
      <>
        <FlowRun
          nodes={workflow.nodes}
          edges={workflow.edges}
          informExecutionChanged={informExecutionChanged}
          execution={execution}
        />
      </>
    ),
  };
  const items =
    executionId && execution?.status != 'Created'
      ? [INFORMATION_TAB, EXECUTION_TAB]
      : [INFORMATION_TAB];

  useEffect(() => {
    if (singleLocationId) {
      if (workflowId) {
        setDetailsLoading(true);
        getWorkflowDetails(workflowId, singleLocationId, (res) => {
          const tWorkflow = res.result || {};
          if (tWorkflow._id) {
            setWorkflow(tWorkflow);
            setDocuments(res.documents);
          }
          setDetailsLoading(false);
        });
      }
    }
    if (executionId) {
      setDetailsLoading(true);
      getExecutionDetails(executionId, (res) => {
        const tExecution = res.result || {};
        if (res.result?._id) {
          setExecution(tExecution);
          setDocuments(res.documents);
          if (tExecution.status == 'Created') {
            setActiveTab(EXECUTION_TAB_KEYS.INFORMATION);
          } else {
            setActiveTab(EXECUTION_TAB_KEYS.EXECUTION);
          }
        }
        setDetailsLoading(false);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workflowId, executionId, singleLocationId]);

  return (
    <>
      <PageWrapper className={classes.wrapper}>
        {detailsLoading && <Loading isPage />}
        <Breadcrumb items={breadcrumbs} />
        {!singleLocationId ? (
          <>
            {/* Select a location<br/> */}
            {/* {JSON.stringify(selectLocationOptions)} */}
            <ReSelect
              options={selectLocationOptions}
              label="Select location"
              name="locationMeta"
              placeholder="Select Locations"
              onChange={(e) => {
                setSingleLocationId(e.value);
              }}
              radius="md"
              variant="primary"
              fontSize="sm"
              autoFocus
            />
          </>
        ) : (
          <>
            <Tabs
              items={items}
              activeTab={activeTab}
              onChange={(val) => {
                setActiveTab(val);
              }}
              headerFullWidth
              headerSize="sm"
            />
            {activeTab == EXECUTION_TAB_KEYS.EXECUTION && (
              <div className={classes.formFooter}>
                <Button
                  variant="primary"
                  isLink
                  type="button"
                  onClick={() => {
                    // navigate back to previous URL
                    onNavigate(-1);
                  }}
                >
                  Cancel
                </Button>
                <Button
                  variant="primary"
                  type="submit"
                  onClick={() => saveExecution('In Progress')}
                >
                  Save Progress
                </Button>
                {/* {missing && JSON.stringify(missing.map(m=>m.form.data.label + ' - ' + m.field.label))} */}
                <Button
                  variant="primary"
                  type="submit"
                  disabled={!missing || missing.length > 0}
                  onClick={() => {
                    saveExecution('Completed');
                    onNavigate('/dashboard');
                  }}
                >
                  Mark as Complete
                </Button>
              </div>
            )}
          </>
        )}
      </PageWrapper>
      <ToastAlert toastRef={notificationAlertRef} />
    </>
  );
};

export default ExecuteWorkflow;
