import cn from 'classnames';
import range from 'lodash/range';
import { USER_STATUS_OPTIONS } from 'src/helper/constants/user';
import { LOCATION_DETAILS_TAB_KEYS } from 'src/pages/LocationDetails/constants';
import Emergency from '../Emergency';
import LocationDetailsTable from '../LocationDetailsTable';
import classes from './TabContent.module.scss';
import {
  getColumns1,
  getColumns2,
  getColumns3,
  getColumns4,
} from './constants';

const DEFAULT_LIST_DATA = {
  data: range(10).map((i) => ({
    key: i + 1,
    id: i + 1,
  })),
  meta: {
    current_page: 1,
    per_page: 10,
    total: 50,
  },
};

const TabContent = ({
  className,
  tabKey,
  onAddContact,
  onEditContact,
  onDeleteContact,
  onAddPlan,
  onViewPlan,
  onDeleteStaff,
  emergencyData,
  onEmergencyPaginationChange,
  onAddStaff,
  roleList,
  onStaffFiltersChanged,
  staffListData,
  onStaffRowClick,
  onEditStaff,
}) => {
  const getContent = () => {
    switch (tabKey) {
      case LOCATION_DETAILS_TAB_KEYS.SUB_SYSTEMS: {
        const columns = getColumns1();
        return (
          <LocationDetailsTable
            title="Sub-Systems"
            columns={columns}
            searchPlaceholder="Search Sub-Systems"
            data={DEFAULT_LIST_DATA}
          />
        );
      }
      case LOCATION_DETAILS_TAB_KEYS.EQUIPMENT: {
        const columns = getColumns2();
        return (
          <LocationDetailsTable
            title="Equipments"
            columns={columns}
            searchPlaceholder="Search Equipment"
            data={DEFAULT_LIST_DATA}
          />
        );
      }
      case LOCATION_DETAILS_TAB_KEYS.EMERGENCY:
        return (
          <Emergency
            onAddContact={onAddContact}
            onEditContact={onEditContact}
            onDeleteContact={onDeleteContact}
            onAddPlan={onAddPlan}
            onViewPlan={onViewPlan}
            data={emergencyData}
            onPaginationChange={onEmergencyPaginationChange}
          />
        );
      case LOCATION_DETAILS_TAB_KEYS.STAFF_LIST: {
        const columns = getColumns3({
          onDelete: onDeleteStaff,
          onEdit: onEditStaff,
        });
        const filterItems = [
          {
            id: 1,
            label: 'Filter by Role',
            items: (roleList || []).map((obj) => ({
              id: obj.id,
              label: obj.role_name,
            })),
          },
          {
            id: 3,
            label: 'Filter by Status',
            items: USER_STATUS_OPTIONS.map((obj) => ({
              id: obj.value,
              label: obj.label,
            })),
          },
        ];
        return (
          <LocationDetailsTable
            title="Staff List"
            columns={columns}
            searchPlaceholder="Search Staff"
            addText="Add Staff"
            onAdd={onAddStaff}
            filterItems={filterItems}
            onAllFiltersChanged={onStaffFiltersChanged}
            data={staffListData}
            onRowClick={onStaffRowClick}
          />
        );
      }
      case LOCATION_DETAILS_TAB_KEYS.CHEMICALS: {
        const columns = getColumns4();
        return (
          <LocationDetailsTable
            title="Chemicals"
            columns={columns}
            searchPlaceholder="Search Chemicals"
            data={DEFAULT_LIST_DATA}
          />
        );
      }
      default:
        return '';
    }
  };
  return <div className={cn(classes.wrapper, className)}>{getContent()}</div>;
};

export default TabContent;
