import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import * as yup from 'yup';
import Breadcrumb from 'src/components/Breadcrumb';
import Button from 'src/components/Button';
import FormGroup from 'src/components/FormFields/FormGroup';
import FormInput from 'src/components/FormFields/Input/FormInput';
import FormSelect from 'src/components/FormFields/Select/FormSelect';
import Loading from 'src/components/Loading';
import PageTitle from 'src/components/Page/PageTitle';
import PageWrapper from 'src/components/Page/PageWrapper';
import ToastAlert from 'src/components/ToastAlert';
import { INPUT_HIGHLIGHT_ERROR_MESSAGE } from 'src/helper/constants';
import { VIEW_PARAM_KEY, isUrlAction } from 'src/helper/constants/routes';
import useOwnNavigate from 'src/helper/hooks/useOwnNavigate';
import useCurrentPath from 'src/helper/hooks/userCurrentPath';
import { notifyPrimary } from 'src/utils/notification';
import { getAllUrlParams } from 'src/utils/routes';
import classes from './GenericCreate.module.scss';
import Field from './components/Field';
import GenericCreateFooter from './components/Footer';
import GenericCreateTitle from './components/Title';
import GenericCreateWrapper from './components/Wrapper';

const formSchema = yup.object().shape({
  description: yup.string().nullable(),
});

const GenericCreate = () => {
  const location = useLocation();
  const urlParams = getAllUrlParams(location?.search);
  const currentPath = useCurrentPath();
  const { onNavigate } = useOwnNavigate();
  const genericId = Number(urlParams.id || 0);
  const isEdit = !!genericId;
  const isView = isUrlAction(urlParams?.[VIEW_PARAM_KEY]) && isEdit;
  const notificationAlertRef = useRef(null);
  const [createRegionLoading, setCreateRegionLoading] = useState(false);
  const getTitle = () => {
    if (isView) return 'View Details';
    return isEdit ? 'Edit View' : 'Create';
  };
  const getSubTitle = () => {
    if (isView) return 'View Details sub title';
    return isEdit ? 'Edit View sub title' : 'Create sub title';
  };
  const getBreadcrumbText = () => {
    if (isView) return 'Generic View Details';
    return isEdit ? 'Generic Edit' : 'Generic Create';
  };
  const breadcrumbs = [
    {
      link: '/generic',
      label: 'Generic',
    },
    {
      label: getBreadcrumbText(),
    },
  ];
  const { handleSubmit, control, watch, setValue, formState, reset } = useForm({
    resolver: yupResolver(formSchema),
    defaultValues: {
      name: '',
      description: '',
      locations: [],
      addedLocations: [],
    },
  });
  const notify = (type, title, description) => {
    notifyPrimary({ ref: notificationAlertRef, type, title, description });
  };
  const onSubmit = async (values) => {
    onNavigate('/generic');
  };

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <GenericCreateWrapper>
        {false && <Loading isPage />}
        <Breadcrumb items={breadcrumbs} />
        <GenericCreateTitle subTitle={getSubTitle()}>
          <span>{getTitle()}</span>
          <Button variant="primary" type="button" onClick={() => {}}>
            Button Goes Here
          </Button>
        </GenericCreateTitle>
        <form
          role="form"
          className={classes.form}
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className={classes.formInner}>
            <h2>Parent Element of the currently edited element</h2>
            {isView ? (
              <>
                <Field label="Select Company" value="Company 1" />
                <Field label="Description" value="Description" />
              </>
            ) : (
              <>
                <FormGroup>
                  <FormSelect
                    options={[
                      {
                        value: 'company1',
                        label: 'Company 1',
                      },
                      {
                        value: 'company2',
                        label: 'Company 2',
                      },
                      {
                        value: 'company3',
                        label: 'Company 3',
                      },
                      {
                        value: 'company4',
                        label: 'Company 4',
                      },
                      {
                        value: 'company5',
                        label: 'Company 5',
                      },
                    ]}
                    label="Select company"
                    name="name"
                    placeholder="Select Company"
                    control={control}
                    radius="md"
                    variant="primary"
                    fontSize="sm"
                    autoFocus
                  />
                </FormGroup>
                <FormGroup>
                  <FormInput
                    label="Description"
                    name="description"
                    placeholder="Enter description"
                    control={control}
                    type="textarea"
                    radius="md"
                    variant="primary"
                    fontSize="sm"
                  />
                </FormGroup>
              </>
            )}

            <div className={classes.moreFieldsWrapper}>
              <h2>More fields</h2>
              {isView ? (
                <>
                  <Field label="Field Title" value="Title" />
                </>
              ) : (
                <>
                  <FormGroup>
                    <FormInput
                      label="Field Title"
                      name="field_title"
                      placeholder="Enter title"
                      control={control}
                      radius="md"
                      variant="primary"
                      fontSize="sm"
                    />
                  </FormGroup>
                </>
              )}
            </div>
          </div>
          {!isView && (
            <GenericCreateFooter>
              <Button
                variant="primary"
                isLink
                type="button"
                onClick={() => {
                  onNavigate('/generic');
                }}
              >
                Cancel
              </Button>
              <Button
                variant="primary"
                type="submit"
                disabled={!formState.isValid}
                loading={createRegionLoading}
              >
                {isEdit ? 'Save Changes' : 'Create'}
              </Button>
            </GenericCreateFooter>
          )}
        </form>
      </GenericCreateWrapper>
      <ToastAlert toastRef={notificationAlertRef} />
    </>
  );
};

export default GenericCreate;
