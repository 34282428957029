import { yupResolver } from '@hookform/resolvers/yup';
import cn from 'classnames';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import Button from 'src/components/Button';
import Modal from 'src/components/Modal';
import { INPUT_HIGHLIGHT_ERROR_MESSAGE } from 'src/helper/constants';
import { LOCATION_EMERGENCY_TYPE } from 'src/helper/constants/location';
import { RESOURCE_DATA_KEY } from 'src/helper/constants/store';
import classes from './AddPlanModal.module.scss';
import UploadDocument from './UploadDocument';

const formSchema = yup.object().shape({
  uploadedFiles: yup
    .array()
    .min(1, INPUT_HIGHLIGHT_ERROR_MESSAGE)
    .of(yup.mixed().required(INPUT_HIGHLIGHT_ERROR_MESSAGE)),
});

const AddPlanModal = ({
  isOpen,
  onClose,
  onSubmit,
  obj,
  title,
  description,
  isSingle,
}) => {
  const [loading, setLoading] = useState(false);
  const type = obj?.type;
  const isEvacuation = type === LOCATION_EMERGENCY_TYPE.EVACUATION_PLAN;
  const { handleSubmit, control, formState } = useForm({
    resolver: yupResolver(formSchema),
    defaultValues: {
      uploadedFiles: [],
    },
  });

  useEffect(() => {
    setLoading(false);
  }, []);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={
        title ||
        (isEvacuation ? 'Add Evacuation Plan' : 'Add Emergency Procedure')
      }
      hideClose
      centered
      wrapClassName={cn(classes.modalWrapper, {
        [classes.isSingle]: isSingle,
      })}
      radius="lg"
    >
      <form
        role="form"
        className={classes.form}
        onSubmit={handleSubmit((values) => {
          setLoading(true);
          onSubmit(values, () => {
            setLoading(false);
          });
        })}
      >
        <div className={classes.formInner}>
          <div className={classes.description}>
            <span>
              {description ||
                (isEvacuation
                  ? 'Upload evacuation plan documents for this location'
                  : 'Upload emergency procedure documents for this location')}
            </span>
          </div>
          <UploadDocument
            control={control}
            name="uploadedFiles"
            isSingle={isSingle}
          />
        </div>
        <div className={classes.formFooter}>
          <Button variant="primary" isLink type="button" onClick={onClose}>
            Cancel
          </Button>
          <Button
            variant="primary"
            type="submit"
            loading={loading}
            disabled={!formState.isValid}
          >
            Add Document{isSingle ? '' : 's'}
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export default AddPlanModal;
