import { useContext } from 'react';
import { LOCATIONS_SCOPE_ATTR_NAME } from 'src/helper/constants';
import { LOCATION_EMERGENCY_TYPE } from 'src/helper/constants/location';
import { RESOURCE_DATA_KEY } from 'src/helper/constants/store';
import useResourceActions from 'src/helper/hooks/useResourceActions';
import { MenuContext } from 'src/helper/providers/MenuProvider';
import {
  LOCATION_DETAILS_TAB_KEYS,
  LOCATION_DETAILS_TAB_SUB_KEYS,
  getAddPlanMessage,
  getContactActionMessage,
  getDeleteActionMessage,
} from './constants';
import { getNewContactBR, getNewPlanBR } from './utils';

let uploadCount = 0;

const useFunction = (locationId, loadTabData, notify, setRoleList) => {
  const { selectedLocationIds } = useContext(MenuContext);
  const selectedLocationIdsString = selectedLocationIds.join(',');
  const {
    onCreateResource,
    onEditResource,
    onDeleteResource,
    onUploadResource,
    onGetResources,
    onDeleteResourceStaff,
  } = useResourceActions();

  const onConfirmedContact = async (values, loadingCb, cb) => {
    let res = null;
    if (values?.id) {
      res = await onEditResource(
        RESOURCE_DATA_KEY.EMERGENCY_CONTACT,
        values.id,
        getNewContactBR({
          ...values,
          location_id: locationId,
        })
      );
    } else {
      res = await onCreateResource(
        RESOURCE_DATA_KEY.EMERGENCY_CONTACT,
        getNewContactBR({
          ...values,
          location_id: locationId,
        })
      );
    }
    if (loadingCb) loadingCb();
    notify(...getContactActionMessage(!!values.id, res?.isSuccess));
    if (res?.isSuccess) {
      loadTabData(
        LOCATION_DETAILS_TAB_KEYS.EMERGENCY,
        LOCATION_DETAILS_TAB_SUB_KEYS.CONTACTS,
        { page: 1, size: 10 }
      );
      if (cb) cb();
    }
  };
  const onConfirmedDelete = async (key, id, data, loadingCb, cb) => {
    const isDeleteLocationStaff = key === RESOURCE_DATA_KEY.LOCATIONS;
    const isAction = isDeleteLocationStaff
      ? onDeleteResourceStaff
      : onDeleteResource;
    const res = await isAction(
      key,
      isDeleteLocationStaff ? locationId : id,
      isDeleteLocationStaff ? data : {}
    );
    if (loadingCb) loadingCb();
    notify(...getDeleteActionMessage(key, res?.isSuccess));
    if (res?.isSuccess) {
      switch (key) {
        case RESOURCE_DATA_KEY.EMERGENCY_CONTACT: {
          loadTabData(
            LOCATION_DETAILS_TAB_KEYS.EMERGENCY,
            LOCATION_DETAILS_TAB_SUB_KEYS.CONTACTS,
            { page: 1, size: 10 }
          );
          break;
        }
        case RESOURCE_DATA_KEY.LOCATIONS: {
          loadTabData(LOCATION_DETAILS_TAB_KEYS.STAFF_LIST, '', {
            page: 1,
            size: 10,
          });
          break;
        }
        default:
          break;
      }
      if (cb) cb();
    }
  };
  const onUploadPlan = async (values, loadingCb, cb) => {
    uploadCount = 0;
    const tFiles = values?.uploadedFiles || [];
    tFiles.map(async (tFile) => {
      const res = await onUploadResource(
        RESOURCE_DATA_KEY.EMERGENCY_DOCUMENT,
        getNewPlanBR(
          {
            type: values.type,
            locationId,
          },
          tFile
        )
      );
      uploadCount = uploadCount + 1;
      if (uploadCount === tFiles.length) {
        if (values.type === LOCATION_EMERGENCY_TYPE.PROCEDURE) {
          loadTabData(
            LOCATION_DETAILS_TAB_KEYS.EMERGENCY,
            LOCATION_DETAILS_TAB_SUB_KEYS.PROCEDURES,
            { page: 1, size: 4 }
          );
        } else {
          loadTabData(
            LOCATION_DETAILS_TAB_KEYS.EMERGENCY,
            LOCATION_DETAILS_TAB_SUB_KEYS.EVACUATION_PLANS,
            { page: 1, size: 4 }
          );
        }
        if (loadingCb) loadingCb();
        if (cb) cb();
        notify(...getAddPlanMessage(true));
      }
    });
  };
  const onReloadRoles = async () => {
    const res = await onGetResources(RESOURCE_DATA_KEY.ROLES, {
      page: 1,
      per_page: 99999,
      [LOCATIONS_SCOPE_ATTR_NAME]: selectedLocationIdsString,
    });
    setRoleList(res?.isSuccess ? res.data : []);
  };

  return { onConfirmedContact, onConfirmedDelete, onUploadPlan, onReloadRoles };
};

export default useFunction;
