import { ReactComponent as SettingsSvg } from 'src/assets/icons/Nav/Settings1.svg';
import { ReactComponent as SettingsActiveSvg } from 'src/assets/icons/Nav/Settings1_Active.svg';
import CreateWorkflow from 'src/pages/CreateWorkflow';
import ExecuteWorkflow from 'src/pages/ExecuteWorkflow';
import Workflow from 'src/pages/Workflow';

const workflowRoutes = [
  {
    path: '/workflow',
    name: 'Workflow',
    icon: <SettingsSvg />,
    iconActive: <SettingsActiveSvg />,
    component: Workflow,
    layout: 'private',
  },
  {
    path: '/workflows/edit/:id',
    name: 'Edit Workflow',
    icon: '',
    iconActive: '',
    component: CreateWorkflow,
    layout: 'private',
  },
  {
    path: '/workflows/execute/:id',
    name: 'Execute Workflow',
    icon: '',
    iconActive: '',
    component: ExecuteWorkflow,
    layout: 'private',
  },
  {
    path: '/workflows/execute/:id/:executionId',
    name: 'Execute Workflow Run',
    icon: '',
    iconActive: '',
    component: ExecuteWorkflow,
    layout: 'private',
  },
  {
    path: '/workflows/create-workflow',
    name: 'Create Workflow',
    icon: '',
    iconActive: '',
    component: CreateWorkflow,
    layout: 'private',
  },
];

export default workflowRoutes;
