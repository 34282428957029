import { ReactComponent as ChartSvg } from 'src/assets/icons/chart.svg';
import { ReactComponent as LocationSvg } from 'src/assets/icons/location.svg';
import Card from 'src/components/Card';
import Map from 'src/components/Map';
import Score from 'src/components/Score';
import ScoreChart from 'src/components/Score/Chart';
import classes from './LocationDetails.module.scss';

const LocationDetailsInfo = ({ markers }) => {
  return (
    <>
      <div className={classes.overviewWrapper}>
        <Card
          title="Omni Score"
          titleIcon={<ChartSvg />}
          headerRight="Last 30 Days"
          contentAlign="horizontal"
        >
          <Score
            score={90}
            width={226}
            activeColor={'#45B942'}
            issuesColor={'#C1FFC0'}
            color="#EEEEEE"
            title=" "
            className={classes.score}
            issues={5}
          />
          <div className={classes.scoreRight}>
            <ScoreChart
              scores={[
                100, 100, 100, 60, 60, 60, 30, 30, 100, 100, 100, 100, 100, 100,
                100, 100, 100, 100, 100, 100, 60, 60, 60, 30, 30, 100, 100, 100,
                60, 60,
              ]}
            />
            <div>
              <div>
                No Issues in the last <b>4</b> days
              </div>
              <div>
                <span>
                  <b>Nov 17</b> - Ammonia Leak
                </span>
                <span>Details</span>
              </div>
              <div>
                <span>
                  <b>Nov 6</b> - Ammonia Leak
                </span>
                <span>Details</span>
              </div>
              <div>
                <span>
                  <b>Nov 5</b> - Ammonia Leak
                </span>
                <span>Details</span>
              </div>
              <div>
                <span>
                  <b>Nov 4</b> - Ammonia Leak
                </span>
                <span>Details</span>
              </div>
            </div>
          </div>
        </Card>
        <Card
          title="Location"
          titleIcon={<LocationSvg width={24} height={24} />}
        >
          {!!markers?.length && (
            <Map className={classes.map} markers={markers} loadedZoom={15} />
          )}
        </Card>
      </div>
    </>
  );
};

export default LocationDetailsInfo;
