import omit from 'lodash/omit';
import { getRealWebsiteUrl } from 'src/utils/website';

export const getNewContactBR = (values) => {
  return {
    ...omit(values, ['favorite', 'id']),
    favourite: !!values.favorite,
    website: getRealWebsiteUrl(values.website) || null,
  };
};
export const getNewPlanBR = (values, tFile) => {
  const tData = new FormData();
  tData.append('document_type', values.type);
  tData.append('location_id', values.locationId);
  tData.append('file', tFile);
  return tData;
};
export const getListFiltersParams = (values, locationId) => {
  const returnObj = {
    search: values?.search || '',
    page: values?.page || 1,
    per_page: values?.size || 10,
    filters: {
      location_id: locationId,
    },
  };
  const filteredRoles = (values?.filters_id || []).filter(
    (v) => !!v && typeof v === 'number'
  );
  const filteredStatuses = (values?.filters_id || []).filter(
    (v) => !!v && typeof v === 'string'
  );
  if (filteredRoles?.length > 0) {
    returnObj.filters.role_ids = filteredRoles;
  }
  if (filteredStatuses?.length > 0) {
    returnObj.filters.statuses = filteredStatuses;
  }
  if (!!values?.sort?.field && !!values?.sort?.method) {
    returnObj.sort_by = values.sort.field;
    returnObj.sort_order = values.sort.method;
  }
  return returnObj;
};
