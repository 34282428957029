import { getMomentDate } from './date';

export const getPlanStatusByTime = (expiredDate) => {
  if (!expiredDate)
    return {
      type: 'expired',
      text: 'Expired',
    };
  const today = getMomentDate('', true, true);
  const tExpiredDate = getMomentDate(expiredDate, false, true);
  const diffDate = tExpiredDate.diff(today, 'days');
  if (!diffDate)
    return {
      type: 'expired',
      text: 'Expired',
    };
  if (diffDate < 30)
    return {
      type: 'warning',
      text: `Exp: ${diffDate} day${diffDate === 1 ? '' : 's'}`,
    };
  return {
    type: 'active',
    text: 'Active',
  };
};
