import { yupResolver } from '@hookform/resolvers/yup';
import { useContext, useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useLocation, useParams } from 'react-router-dom';
import * as yup from 'yup';
import Breadcrumb from 'src/components/Breadcrumb';
import Loading from 'src/components/Loading';
import ConfirmModal from 'src/components/Modal/Confirm';
import ToastAlert from 'src/components/ToastAlert';
import { VIEW_PARAM_KEY, isUrlAction } from 'src/helper/constants/routes';
import { RESOURCE_DATA_KEY } from 'src/helper/constants/store';
import useOwnNavigate from 'src/helper/hooks/useOwnNavigate';
import useResourceActions from 'src/helper/hooks/useResourceActions';
import { MenuContext } from 'src/helper/providers/MenuProvider';
import { useMetadata } from 'src/helper/providers/MetadataProvider';
import GenericCreateTitle from 'src/pages/GenericCreate/components/Title';
import GenericCreateWrapper from 'src/pages/GenericCreate/components/Wrapper';
import { notifyPrimary } from 'src/utils/notification';
import { getAllUrlParams, getCreateEditUrlAction } from 'src/utils/routes';
import classes from './CreateStaff.module.scss';
import CreateStaffForm from './components/Form';
import { useActions, useIndexData } from './selectorData';
import {
  getBreadcrumbs,
  getCreateUserPayload,
  getSubTitle,
  getTitle,
} from './utils';

const formSchema = yup.object().shape({
  first_name: yup.string().required('First name is required!'),
  last_name: yup.string().required('Last name is required!'),
  role_id: yup.mixed().required('Role is required!'),
  supervisor_id: yup.mixed().required('Supervisor is required!'),
  phone: yup.string().required('First name is required!'),
  email: yup
    .string()
    .required('Email is required!')
    .email('Email is not valid!'),
});

let confirmFn = null;

const CreateStaff = () => {
  const { prevPath, onNavigate } = useOwnNavigate();
  const { selectedLocations, getSingleLocationOption } =
    useContext(MenuContext);
  const { rolesLoading, usersLoading } = useMetadata();
  const { onCreateResource, onEditResource } = useResourceActions();
  const { getResourceDetails, getLocationDetails } = useActions();
  const { userDetails, locationDetails } = useIndexData();
  const location = useLocation();
  const urlParams = getAllUrlParams(location?.search);
  const params = useParams();
  const locationId = Number(params.locationId || 0);
  const urlLocationId = Number(urlParams?.location || 0);
  const backUrl =
    prevPath || `${locationId ? `/locations/${locationId}` : '/admin/users'}`;
  const userId = Number(urlParams.id || 0);
  const isViewDetails = isUrlAction(urlParams?.[VIEW_PARAM_KEY]) && !!userId;
  const isEdit = !isUrlAction(urlParams?.[VIEW_PARAM_KEY]) && !!userId;
  const notificationAlertRef = useRef(null);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [detailsLoading, setDetailsLoading] = useState(false);
  const [locationDetailsLoading, setLocationDetailsLoading] = useState(false);
  const [confirmObj, setConfirmObj] = useState(null);
  const breadcrumbs = getBreadcrumbs({
    locationId,
    isViewDetails,
    isEdit,
    staffName: userDetails?.name,
    locationName: locationDetails?.name,
  });
  const { handleSubmit, control, setValue, formState, reset } = useForm({
    resolver: yupResolver(formSchema),
    defaultValues: {
      first_name: '',
      last_name: '',
      role_id: null,
      location_ids: urlLocationId
        ? [getSingleLocationOption(urlLocationId)]
        : selectedLocations,
      phone: '',
      email: '',
      supervisor_id: null,
    },
  });
  const notify = (type, title, description) => {
    notifyPrimary({ ref: notificationAlertRef, type, title, description });
  };
  const reloadUserDetails = (cb) => {
    setDetailsLoading(true);
    getResourceDetails(RESOURCE_DATA_KEY.USERS, userId, (res) => {
      setDetailsLoading(false);
      if (cb) cb(res);
    });
  };
  const reloadLocationDetails = () => {
    setLocationDetailsLoading(true);
    getLocationDetails(locationId, () => {
      setLocationDetailsLoading(false);
    });
  };
  const onSubmit = async (values) => {
    setConfirmObj({
      title: 'Confirm New Staff',
      description:
        'Confirm that you are creating this new staff and the entered details are correct and related to the new staff.',
    });
    confirmFn = async () => {
      if (!submitLoading) {
        setSubmitLoading(true);
        const tPayload = getCreateUserPayload(values);
        let res = null;
        if (isEdit) {
          tPayload.status = userDetails?.status || 'active';
          res = await onEditResource(RESOURCE_DATA_KEY.USERS, userId, tPayload);
        } else {
          tPayload.status = 'active';
          res = await onCreateResource(RESOURCE_DATA_KEY.USERS, tPayload);
        }
        setSubmitLoading(false);
        if (res.isSuccess) {
          onNavigate(getCreateEditUrlAction(backUrl, isEdit));
        } else {
          notify(
            'error',
            'Error',
            `${isEdit ? 'Edit' : 'Create'} User failed!`
          );
        }
      }
    };
  };

  useEffect(() => {
    if (userId) {
      reloadUserDetails((res) => {
        if (isEdit) {
          const editObj = res?.data;
          if (editObj?.id) {
            const resetObj = {
              first_name: editObj.first_name || '',
              last_name: editObj.last_name || '',
              role_id: editObj.role_id ? String(editObj.role_id) : null,
              email: editObj.email || '',
              phone: editObj.phone || '',
              supervisor_id: editObj.supervisor_id
                ? String(editObj.supervisor_id)
                : null,
              location_ids: !editObj.location_ids?.length
                ? selectedLocations
                : editObj.location_ids
                    .map((id) => {
                      return getSingleLocationOption(id);
                    })
                    .filter((v) => !!v),
            };
            reset(resetObj);
          }
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);
  useEffect(() => {
    if (locationId) {
      reloadLocationDetails();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locationId]);
  useEffect(() => {
    confirmFn = null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <GenericCreateWrapper>
        {(detailsLoading ||
          rolesLoading ||
          usersLoading ||
          locationDetailsLoading) && <Loading isPage />}
        <Breadcrumb items={breadcrumbs} />
        <GenericCreateTitle
          subTitle={getSubTitle(isViewDetails, isEdit, locationId)}
          className={classes.pageTitle}
        >
          <span>
            {getTitle({ isViewDetails, isEdit, userDetails, locationId })}
          </span>
        </GenericCreateTitle>
        <CreateStaffForm
          onSubmit={handleSubmit(onSubmit)}
          isViewDetails={isViewDetails}
          userDetails={userDetails}
          control={control}
          setValue={setValue}
          formState={formState}
          isEdit={isEdit}
          submitLoading={submitLoading}
          userId={userId}
          onCancel={() => {
            setConfirmObj({
              title: 'Confirm',
              description:
                'Are you sure you want to exit without saving this new staff? All information that has been entered will be deleted.',
              cancelText: 'Continue Editing',
              submitText: 'Cancel Creation',
            });
            confirmFn = () => {
              onNavigate(backUrl);
            };
          }}
        />
      </GenericCreateWrapper>
      {!!confirmObj && (
        <ConfirmModal
          isOpen
          onClose={() => {
            confirmFn = null;
            setConfirmObj(null);
          }}
          onSubmit={async (cb) => {
            if (cb) cb();
            if (confirmFn) confirmFn();
            setConfirmObj(null);
          }}
          title={confirmObj?.title}
          submitText={confirmObj?.submitText}
          cancelText={confirmObj?.cancelText}
          size="sm"
          fontSize="sm"
          className={classes.confirmModalWrapper}
          type="confirmation"
        >
          {confirmObj?.description}
        </ConfirmModal>
      )}
      <ToastAlert toastRef={notificationAlertRef} />
    </>
  );
};

export default CreateStaff;
