import React from 'react';
import { ReactComponent as MoreSvg } from 'src/assets/icons/more.svg';
import Dropdown from 'src/components/Dropdown';
import StatusTag from 'src/components/Tag/Status';
import { renderDateColumn, renderNormalColumn } from 'src/utils/table';

export const getColumns1 = () => {
  return [
    {
      key: 'name',
      label: 'Name',
      render: (val, obj) => 'Sub-system 1',
      sortable: true,
      width: 317,
    },
    {
      key: 'archiveReason',
      label: 'Status',
      render: (val) => <StatusTag value="active" />,
      sortable: true,
      width: 120,
    },
    {
      key: 'archivedDate',
      label: 'Last Inspection',
      render: (val) => 'Nov 26, 2024',
      sortable: true,
      width: 200,
    },
    {
      key: 'updated',
      label: 'Updated',
      render: (val) => 'Nov 26, 2024',
      sortable: true,
      width: 200,
    },
    {
      key: 'archivedBy',
      label: 'Updated By',
      render: (val) => 'Shane McGonigal',
      sortable: true,
      width: 180,
    },
    {
      key: 'documents',
      label: 'Documents',
      render: (val) => '4 Documents',
      sortable: true,
      width: 180,
    },
    {
      key: 'action',
      width: 56,
      maxWidth: 56,
      render: (_, obj) => {
        return (
          <Dropdown
            items={[
              {
                key: 'action',
                label: 'Action',
                onClick: () => {},
              },
            ]}
          >
            <MoreSvg />
          </Dropdown>
        );
      },
    },
  ];
};

export const getColumns2 = () => {
  return [
    {
      key: 'name',
      label: 'Name',
      render: (val, obj) => 'Equipment 1',
      sortable: true,
      width: 317,
    },
    {
      key: 'archiveReason',
      label: 'Status',
      render: (val) => <StatusTag value="active" />,
      sortable: true,
      width: 120,
    },
    {
      key: 'sub',
      label: 'Sub-System',
      render: (val) => <span>Sub-system 1</span>,
      sortable: true,
      width: 180,
    },
    {
      key: 'last',
      label: 'Last Maint.',
      render: (val) => <span>Nov 5, 2024</span>,
      sortable: true,
      width: 180,
    },
    {
      key: 'last1',
      label: 'Maint. by',
      render: (val) => <span>Roman K.</span>,
      sortable: true,
      width: 180,
    },
    {
      key: 'action',
      width: 56,
      maxWidth: 56,
      render: (_, obj) => {
        return (
          <Dropdown
            items={[
              {
                key: 'action',
                label: 'Action',
                onClick: () => {},
              },
            ]}
          >
            <MoreSvg />
          </Dropdown>
        );
      },
    },
  ];
};

export const getColumns3 = ({ onDelete, onEdit }) => {
  return [
    {
      key: 'name',
      label: 'Name',
      render: renderNormalColumn,
      sortable: true,
      width: 317,
    },
    {
      key: 'status',
      label: 'Status',
      render: (val) => <StatusTag value={val} />,
      sortable: true,
      width: 150,
    },
    {
      key: 'role_name',
      label: 'Role',
      render: renderNormalColumn,
      width: 150,
      sortable: true,
    },
    {
      key: 'phone',
      label: 'Phone',
      render: renderNormalColumn,
      width: 150,
    },
    {
      key: 'email',
      label: 'Email',
      render: renderNormalColumn,
      width: 250,
    },
    {
      key: 'action',
      width: 56,
      maxWidth: 56,
      render: (_, obj) => {
        return (
          <Dropdown
            items={[
              {
                key: 'edit',
                label: 'Edit Staff',
                onClick: () => {
                  onEdit(obj);
                },
              },
              {
                key: 'remove',
                label: 'Remove Staff',
                onClick: () => {
                  onDelete(obj);
                },
              },
            ]}
          >
            <MoreSvg />
          </Dropdown>
        );
      },
    },
  ];
};

export const getColumns4 = () => {
  return [
    {
      key: 'name',
      label: 'Name',
      render: (val, obj) => 'Ammonia (NH₃)',
      sortable: true,
      width: 317,
    },
    {
      key: 'archiveReason',
      label: 'CAS Number',
      render: (val) => <span>7664-41-7</span>,
      width: 150,
    },
    {
      key: 'sub',
      label: 'tier 2 reportable',
      render: (val) => <span>Yes</span>,
      sortable: true,
      width: 230,
    },
    {
      key: 'last',
      label: 'Last updated',
      render: (val) => <span>Oct 2, 2024</span>,
      sortable: true,
      width: 200,
    },
    {
      key: 'last1',
      label: 'Quantity',
      render: (val) => <span>1,000 gls</span>,
      sortable: true,
      width: 150,
    },
    {
      key: 'action',
      width: 56,
      maxWidth: 56,
      clickable: false,
      render: (_, obj) => {
        return (
          <Dropdown
            items={[
              {
                key: 'action',
                label: 'Action',
                onClick: () => {},
              },
            ]}
          >
            <MoreSvg />
          </Dropdown>
        );
      },
    },
  ];
};
