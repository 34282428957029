import cn from 'classnames';
import { STATUS_TAGS } from 'src/helper/constants/status';
import classes from './Tag.module.scss';

const StatusTag = ({ className, value }) => {
  if (!value) return '-';
  const tStatus = STATUS_TAGS[value];
  return (
    <span
      className={cn(
        classes.statusTag,
        classes[`color_${tStatus?.color}`],
        className
      )}
    >
      <span>{tStatus?.label}</span>
    </span>
  );
};

export default StatusTag;
