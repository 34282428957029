import { ReactComponent as ArchiveSvg } from 'src/assets/icons/archive.svg';
import { ReactComponent as ArrowRight2Svg } from 'src/assets/icons/arrow-right2.svg';
import Icon from 'src/components/Icon';
import TableActionDropdown from 'src/components/Table/ActionDropdown';
import StatusTag from 'src/components/Tag/Status';
import { renderDateColumn, renderNormalColumn } from 'src/utils/table';
import classes from './HistoryModal.module.scss';

export const getColumns = ({ onView, onDelete }) => {
  return [
    {
      key: 'name',
      label: 'Name',
      render: renderNormalColumn,
      width: 217,
    },
    {
      key: 'updated_by',
      label: 'Updated by',
      render: (val) => val?.name || '-',
      sortable: true,
      width: 150,
    },
    {
      key: 'date_added',
      label: 'Date Added',
      render: renderDateColumn,
      sortable: true,
      width: 150,
    },
    {
      key: 'action',
      width: 120,
      maxWidth: 120,
      render: (_, obj) => {
        return (
          <span className={classes.actions}>
            <Icon
              icon={<ArchiveSvg />}
              activeIcon={<ArchiveSvg />}
              size={20}
              onClick={() => {
                onDelete(obj);
              }}
            />
            <Icon
              icon={<ArrowRight2Svg />}
              activeIcon={<ArrowRight2Svg />}
              size={20}
              onClick={() => {
                onView(obj);
              }}
            />
          </span>
        );
      },
    },
  ];
};
