import cn from 'classnames';
import { ReactComponent as LocationSvg } from 'src/assets/icons/location.svg';
import { ReactComponent as MoreSvg } from 'src/assets/icons/more.svg';
import Dropdown from 'src/components/Dropdown';
import Score from 'src/components/Score';
import classes from './Card.module.scss';

const RegionCard = ({
  className,
  onEdit,
  onDelete,
  data,
  onView,
  hideView,
}) => {
  const actionDropdown =
    !!onEdit || !!onDelete
      ? [
          ...(onEdit
            ? [
                {
                  key: 'edit',
                  label: 'Edit Region',
                  onClick: onEdit,
                },
              ]
            : []),
          ...(onDelete
            ? [
                {
                  key: 'delete',
                  label: 'Delete Region',
                  onClick: onDelete,
                },
              ]
            : []),
        ]
      : [];
  const locationCount = Number(data?.locations_count || 0);
  return (
    <div className={cn(classes.wrapper, className)}>
      <div className={classes.header}>
        <span>{data?.name}</span>
        {actionDropdown.length > 0 && (
          <Dropdown items={actionDropdown}>
            <MoreSvg />
          </Dropdown>
        )}
      </div>
      <div className={classes.content}>
        <Score
          score={95}
          width={226}
          activeColor={'#45B942'}
          issuesColor={'#C1FFC0'}
          color="#EEEEEE"
          title=" "
          className={classes.score}
          issues={2}
        />
        {/* <div>
          <span>Omni Score Goes Here</span>
          <span>0 Issues</span>
        </div> */}
      </div>
      <div className={classes.description}>{data?.description}</div>
      <div className={classes.footer}>
        <span>
          <LocationSvg />
          <span>
            {locationCount} Location{locationCount === 1 ? '' : 's'}
          </span>
        </span>
        {hideView ? <span></span> : <span onClick={onView}>View Region</span>}
      </div>
    </div>
  );
};

export default RegionCard;
