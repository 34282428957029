import { ReactComponent as ArchivedSvg } from 'src/assets/icons/Nav/Archived.svg';
import { ReactComponent as ArchivedActiveSvg } from 'src/assets/icons/Nav/Archived_Active.svg';
import { ReactComponent as DashboardSvg } from 'src/assets/icons/Nav/Dashboard.svg';
import { ReactComponent as DashboardActiveSvg } from 'src/assets/icons/Nav/Dashboard_Active.svg';
import { ReactComponent as SettingsSvg } from 'src/assets/icons/Nav/Settings1.svg';
import { ReactComponent as SettingsActiveSvg } from 'src/assets/icons/Nav/Settings1_Active.svg';
import Archived from 'src/pages/Archived';
import Dashboard from 'src/pages/Dashboard';
import Generic from 'src/pages/Generic';
import GenericCreate from 'src/pages/GenericCreate';
import NotFound from 'src/pages/NotFound';
import Settings from 'src/pages/Settings';
import adminRoutes from './admin';
import authRoutes from './auth';
import executionsRoutes from './executions';
import locationRoutes from './location';
import regionRoutes from './region';
import repositoryRoutes from './repository';
import staffRoutes from './staff';
import workflowRoutes from './workflow';

const routes = [
  ...adminRoutes,
  ...staffRoutes,
  ...locationRoutes,
  ...regionRoutes,
  ...repositoryRoutes,
  ...executionsRoutes,
  ...workflowRoutes,
  ...authRoutes,
  {
    path: '/404-not-found',
    name: 'NotFound',
    icon: <DashboardSvg />,
    iconActive: <DashboardActiveSvg />,
    component: NotFound,
    layout: 'private',
  },
  {
    path: '/',
    name: 'Home',
    icon: <DashboardSvg />,
    iconActive: <DashboardActiveSvg />,
    component: Dashboard,
    layout: 'private',
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    icon: <DashboardSvg />,
    iconActive: <DashboardActiveSvg />,
    component: Dashboard,
    layout: 'private',
  },
  {
    path: '/archived',
    name: 'Archived',
    icon: <ArchivedSvg />,
    iconActive: <ArchivedActiveSvg />,
    component: Archived,
    layout: 'private',
  },
  {
    path: '/settings',
    name: 'Settings',
    icon: <SettingsSvg />,
    iconActive: <SettingsActiveSvg />,
    component: Settings,
    layout: 'private',
  },
  {
    path: '/generic',
    name: 'Generic',
    icon: '',
    iconActive: '',
    component: Generic,
    layout: 'private',
  },
  {
    path: '/generic/create',
    name: 'GenericCreate',
    icon: '',
    iconActive: '',
    component: GenericCreate,
    layout: 'private',
  },
];

export default routes;
