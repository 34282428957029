import React from 'react';
import { getRealLocationId } from 'src/helper/providers/utils';
import { getSelectValue, getSelectValues } from 'src/utils/form';

export const getCreateUserPayload = (values) => {
  const returnObj = {
    ...values,
    supervisor_id: Number(getSelectValue(values.supervisor_id)) || null,
    role_id: Number(getSelectValue(values.role_id)) || null,
    location_ids: getSelectValues(values.location_ids).map((id) =>
      getRealLocationId(id)
    ),
  };
  return returnObj;
};
export const getAssignedLocationsRolesPayload = (items) => {
  return (items || []).map((obj) => {
    return {
      location_id: obj.id,
      roles: (obj.roles || []).map((v) => Number(v)),
    };
  });
};
export const getTitle = ({
  isViewDetails,
  isEdit,
  userDetails,
  locationId,
}) => {
  if (isViewDetails) return `${userDetails?.name || ''}`;
  return isEdit
    ? locationId
      ? 'Edit Staff'
      : `Edit User - ${userDetails?.name}`
    : 'Create New User';
};
export const getSubTitle = (isViewDetails, isEdit, locationId) => {
  if (!!isEdit && !!locationId)
    return (
      <>
        An Americold refrigeration facility specializes in
        temperature-controlled storage and logistics, supporting the
        distribution of perishable goods efficiently and reliably.
      </>
    );
  if (isViewDetails) return '';
  return (
    <>
      Add new full-time staff members to the system and assign them to their
      designated locations.
    </>
  );
};
export const getBreadcrumbText = ({
  isViewDetails,
  isEdit,
  staffName,
  locationId,
}) => {
  if (isViewDetails) return staffName;
  return isEdit ? `Edit ${locationId ? 'Staff' : 'User'}` : 'Create New User';
};
export const getBreadcrumbs = ({
  locationId,
  isViewDetails,
  isEdit,
  staffName,
  locationName,
}) => {
  return [
    ...(locationId
      ? [
          {
            link: '/locations',
            label: 'Locations',
          },
          {
            link: `/locations/${locationId}`,
            label: locationName,
          },
        ]
      : [
          {
            link: '/admin/users',
            label: 'User Management',
          },
        ]),
    {
      label: getBreadcrumbText({
        isViewDetails,
        isEdit,
        staffName,
        locationId,
      }),
    },
  ];
};
