import { yupResolver } from '@hookform/resolvers/yup';
import cn from 'classnames';
import { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { Card, CardBody, CardHeader } from 'reactstrap';
import * as yup from 'yup';
import { apiResetPassword } from 'src/api';
import Button from 'src/components/Button';
import FormGroup from 'src/components/FormFields/FormGroup';
import FormInputPassword from 'src/components/FormFields/InputPassword/FormInputPassword';
import Logo from 'src/components/Logo';
import ToastAlert from 'src/components/ToastAlert';
import { INPUT_HIGHLIGHT_ERROR_MESSAGE } from 'src/helper/constants';
import useOwnNavigate from 'src/helper/hooks/useOwnNavigate';
import { getAllUrlParams } from 'src/utils/routes';
import authClasses from '../Auth.module.scss';
import { useIndexData } from './selectorData';

const formSchema = yup.object().shape({
  password: yup
    .string()
    .min(8, 'Password must be at least 8 characters')
    .required('This field is required'),
  confirmPassword: yup
    .string()
    .required('This field is required')
    .oneOf([yup.ref('password'), null], 'Passwords must match'),
});

const ResetPassword = () => {
  const location = useLocation();
  const { onNavigate } = useOwnNavigate();
  const urlParams = getAllUrlParams(location?.search);
  const { isLoggedIn } = useIndexData();
  const notificationAlertRef = useRef(null);
  const [success, setSuccess] = useState(null);
  const [loading, setLoading] = useState(null);
  const {
    handleSubmit,
    control,
    setError,
    formState: { isDirty, isValid },
  } = useForm({
    resolver: yupResolver(formSchema),
    defaultValues: {
      password: '',
      confirmPassword: '',
    },
  });
  const notify = (type, title) => {
    let options = {
      place: 'tc',
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {title}
          </span>
        </div>
      ),
      type: type,
      icon: 'ni ni-bell-55',
      autoDismiss: 7,
    };
    notificationAlertRef.current.notificationAlert(options);
  };
  const handleApiError = () => {
    setError('password', {
      type: 'required',
      message: INPUT_HIGHLIGHT_ERROR_MESSAGE,
    });
    setError('confirmPassword', {
      type: 'required',
      message: 'Email or password is invalid. Please try again.',
    });
  };

  const onSubmit = async (data) => {
    try {
      setLoading(true);
      const res = await apiResetPassword({
        email: urlParams.email,
        token: urlParams.token,
        password: data.password,
        password_confirmation: data.confirmPassword,
      });
      if (res?.isSuccess) {
        setSuccess(true);
        setTimeout(() => {
          onNavigate('/login');
        }, 3000);
      } else {
        handleApiError();
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      handleApiError();
    }
  };

  useEffect(() => {
    if (!urlParams?.token || !urlParams?.email) {
      notify(
        'danger',
        'Reset password required token and email. Please try again!'
      );
      setTimeout(() => {
        onNavigate('/login');
      }, 1500);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoggedIn) {
    return <Navigate to="/dashboard" replace />;
  }
  return (
    <>
      <Card className={cn('border-0 mb-0', authClasses.card)}>
        <CardHeader className="bg-transparent p-0 border-0">
          <div className="text-muted text-center">
            <Logo variant="authentication" className={authClasses.logo} />
            <p className={authClasses.subtitle}>New Password</p>
            <p className={authClasses.description}>
              Enter and confirm your new password below
            </p>
          </div>
        </CardHeader>
        <CardBody className={authClasses.cardBody}>
          {success ? (
            <>
              <div className={authClasses.alert}>
                <span>Password updated successfully</span>
              </div>
              <div className={authClasses.text}>
                <span>Redirecting...</span>
              </div>
            </>
          ) : (
            <>
              {!urlParams?.token || !urlParams?.email ? (
                <></>
              ) : (
                <form
                  role="form"
                  className={authClasses.form}
                  onSubmit={handleSubmit(onSubmit)}
                >
                  <div className={authClasses.formFields}>
                    <FormGroup>
                      <FormInputPassword
                        name="password"
                        placeholder="New Password"
                        control={control}
                        variant="primary"
                      />
                    </FormGroup>
                    <FormGroup>
                      <FormInputPassword
                        name="confirmPassword"
                        placeholder="Confirm New Password"
                        control={control}
                        variant="primary"
                      />
                    </FormGroup>
                  </div>
                  <div className="text-center">
                    <Button
                      className={cn(authClasses.button, 'mt-4')}
                      type="submit"
                      variant="primary"
                      loading={loading}
                      disabled={!isDirty || !isValid}
                    >
                      Submit
                    </Button>
                  </div>
                </form>
              )}
            </>
          )}
        </CardBody>
      </Card>
      <ToastAlert toastRef={notificationAlertRef} />
    </>
  );
};

export default ResetPassword;
