import isEmpty from 'lodash/isEmpty';
import { useNavigate } from 'react-router-dom';
import Button from 'src/components/Button';
import FieldsLabel from 'src/components/Form/FieldsLabel';
import FieldsRow from 'src/components/Form/FieldsRow';
import FormGroup from 'src/components/FormFields/FormGroup';
import FormInput from 'src/components/FormFields/Input/FormInput';
import FormSelect from 'src/components/FormFields/Select/FormSelect';
import SelectLocation from 'src/components/FormFields/Select/Location';
import PageFooter from 'src/components/Page/PageFooter';
import { useMetadata } from 'src/helper/providers/MetadataProvider';
import CreateStaffView from '../View';
import classes from './Form.module.scss';

const CreateStaffForm = ({
  onSubmit,
  isViewDetails,
  userDetails,
  control,
  formState,
  isEdit,
  submitLoading,
  setValue,
  userId,
  onCancel,
}) => {
  const { roleOptions, userOptions } = useMetadata();

  return (
    <>
      {isViewDetails ? (
        <CreateStaffView userDetails={userDetails} />
      ) : (
        <form role="form" className="s-form" onSubmit={onSubmit}>
          <div className="s-form-inner">
            <FieldsRow col={2}>
              <FormGroup>
                <FormInput
                  required
                  label="First Name"
                  name="first_name"
                  placeholder="Enter first name"
                  control={control}
                  radius="md"
                  variant="primary"
                  fontSize="sm"
                />
              </FormGroup>
              <FormGroup>
                <FormInput
                  required
                  label="Last Name"
                  name="last_name"
                  placeholder="Enter last name"
                  control={control}
                  radius="md"
                  variant="primary"
                  fontSize="sm"
                />
              </FormGroup>
            </FieldsRow>
            <FormGroup>
              <FormSelect
                options={roleOptions}
                label="Staff Role"
                name="role_id"
                placeholder="Select"
                control={control}
                radius="md"
                variant="primary"
                fontSize="sm"
                required
              />
            </FormGroup>
            <FormGroup>
              <SelectLocation
                name="location_ids"
                onChange={(val) => {
                  setValue('location_ids', val, {
                    shouldDirty: true,
                    shouldValidate: true,
                  });
                }}
                control={control}
                label="Location Assignment"
                required
              />
            </FormGroup>
            <FieldsLabel>Contact Information</FieldsLabel>
            <FieldsRow col={2}>
              <FormGroup>
                <FormInput
                  required
                  label="Phone Number"
                  name="phone"
                  placeholder="000-000-0000"
                  control={control}
                  radius="md"
                  variant="primary"
                  fontSize="sm"
                />
              </FormGroup>
              <FormGroup>
                <FormInput
                  required
                  label="Email Address"
                  name="email"
                  placeholder="name@email.com"
                  control={control}
                  radius="md"
                  variant="primary"
                  fontSize="sm"
                />
              </FormGroup>
            </FieldsRow>
            <FormGroup>
              <FormSelect
                options={userOptions.filter(
                  (obj) =>
                    !userId ||
                    (!!userId && String(userId) !== String(obj.value))
                )}
                label="Immediate supervisor"
                name="supervisor_id"
                placeholder="Select"
                control={control}
                radius="md"
                variant="primary"
                fontSize="sm"
                required
              />
            </FormGroup>
          </div>
          {!isViewDetails && (
            <PageFooter fixed>
              <Button variant="gray" type="button" onClick={onCancel}>
                Cancel
              </Button>
              <Button
                variant="primary"
                type="submit"
                disabled={!formState.isValid || isEmpty(formState.dirtyFields)}
                loading={submitLoading}
              >
                {isEdit ? 'Save Changes' : 'Create'}
              </Button>
            </PageFooter>
          )}
        </form>
      )}
    </>
  );
};

export default CreateStaffForm;
