import Tabs from 'src/components/Tabs';
import { tabParams } from 'src/helper/constants/routes';
import useOwnNavigate from 'src/helper/hooks/useOwnNavigate';
import { getRelativePathWithParams } from 'src/utils/routes';
import {
  LOCATION_DETAILS_TAB_KEYS,
  LOCATION_DETAILS_TAB_SUB_KEYS,
} from './constants';

const DetailsTab = ({ items, activeTab, setActiveTab, loadTabData }) => {
  const { onNavigate, fullPathname } = useOwnNavigate();
  return (
    <>
      <Tabs
        items={items}
        activeTab={activeTab}
        onChange={(val) => {
          setActiveTab(val);
          if (val !== activeTab) {
            onNavigate(getRelativePathWithParams(fullPathname, tabParams(val)));
            if (val === LOCATION_DETAILS_TAB_KEYS.EMERGENCY) {
              loadTabData(val, LOCATION_DETAILS_TAB_SUB_KEYS.CONTACTS, {
                page: 1,
                size: 10,
              });
              loadTabData(val, LOCATION_DETAILS_TAB_SUB_KEYS.PROCEDURES, {
                page: 1,
                size: 4,
              });
              loadTabData(val, LOCATION_DETAILS_TAB_SUB_KEYS.EVACUATION_PLANS, {
                page: 1,
                size: 4,
              });
            } else {
              loadTabData(val, '', { page: 1, size: 10 });
            }
          }
        }}
        headerFullWidth
        headerSize="sm"
      />
    </>
  );
};

export default DetailsTab;
