import { useController } from 'react-hook-form';
import FormGroup from 'src/components/FormFields/FormGroup';
import Upload from 'src/components/FormFields/Upload';
import UploadContent from 'src/components/FormFields/Upload/Content';
import { getMomentDate } from 'src/utils/date';
import classes from './AddPlanModal.module.scss';

const UploadDocument = ({ control, name, isSingle }) => {
  const today = getMomentDate('', true);
  const expiredDate = today.clone().add(365, 'days');
  const { field } = useController({
    name,
    control,
  });
  const uploadedFiles = field.value || [];

  return (
    <>
      {(!isSingle || (isSingle && uploadedFiles.length === 0)) && (
        <FormGroup>
          <Upload
            supportText="Supported formats: .doc, .xlsx, .pdf"
            accept=".doc,.docx,.xlsx,.pdf"
            onChange={(tFiles) => {
              const tValue = [...uploadedFiles, ...tFiles];
              const e = {
                target: {
                  name,
                  value: tValue,
                },
              };
              field.onChange(e);
            }}
            isSingle={isSingle}
          />
        </FormGroup>
      )}
      {uploadedFiles.length > 0 && (
        <div className={classes.uploadResult}>
          {!isSingle && (
            <span>
              Documents are valid for one year and will expire on{' '}
              {expiredDate.format('MMM Do YYYY')}.
            </span>
          )}
          <UploadContent
            data={uploadedFiles}
            onDelete={(index) => {
              const tValue = uploadedFiles.filter((obj, i) => i !== index);
              const e = {
                target: {
                  name,
                  value: tValue,
                },
              };
              field.onChange(e);
            }}
          />
          {isSingle && (
            <span>
              Documents are valid for one year and will expire on{' '}
              {expiredDate.format('MMM Do YYYY')}.
            </span>
          )}
        </div>
      )}
    </>
  );
};

export default UploadDocument;
