import cn from 'classnames';
import classes from './Icon.module.scss';

const Icon = ({ className, icon, activeIcon, onClick, size }) => {
  return (
    <span
      className={cn(
        classes.wrapper,
        {
          [classes.hasClick]: !!onClick,
        },
        className
      )}
      style={{ width: size && `${size}px`, height: size && `${size}px` }}
    >
      <span
        className={classes.icon}
        style={{ width: size && `${size}px`, height: size && `${size}px` }}
      >
        {icon}
      </span>
      <span
        className={classes.activeIcon}
        onClick={onClick}
        style={{ width: size && `${size}px`, height: size && `${size}px` }}
      >
        {activeIcon}
      </span>
    </span>
  );
};

export default Icon;
