import { yupResolver } from '@hookform/resolvers/yup';
import { useContext, useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useLocation, useParams } from 'react-router-dom';
import * as yup from 'yup';
import Breadcrumb from 'src/components/Breadcrumb';
import Button from 'src/components/Button';
import FormGroup from 'src/components/FormFields/FormGroup';
import FormInput from 'src/components/FormFields/Input/FormInput';
import FormSelect from 'src/components/FormFields/Select/FormSelect';
import SelectLocation from 'src/components/FormFields/Select/Location';
import Loading from 'src/components/Loading';
import ToastAlert from 'src/components/ToastAlert';
import {
  MENU_ITEM_DEFAULT_TYPE_OPTION,
  MENU_ITEM_TYPE_OPTIONS,
  NONE_OPTION,
} from 'src/helper/constants/option';
import { VIEW_PARAM_KEY, isUrlAction } from 'src/helper/constants/routes';
import useDynamicMenu from 'src/helper/hooks/useDynamicMenu';
import useOptions from 'src/helper/hooks/useOptions';
import useOwnNavigate from 'src/helper/hooks/useOwnNavigate';
import useCurrentPath from 'src/helper/hooks/userCurrentPath';
import { MenuContext } from 'src/helper/providers/MenuProvider';
import Field from 'src/pages/GenericCreate/components/Field';
import GenericCreateFooter from 'src/pages/GenericCreate/components/Footer';
import GenericCreateTitle from 'src/pages/GenericCreate/components/Title';
import GenericCreateWrapper from 'src/pages/GenericCreate/components/Wrapper';
import { notifyPrimary } from 'src/utils/notification';
import { getAllUrlParams } from 'src/utils/routes';
import classes from './MenuItemsCreate.module.scss';
import {
  onCreateMenuItem,
  onEditMenuItem,
  useActions,
  useIndexData,
} from './selectorData';
import { getCreateMenuPayload } from './utils';

const formSchema = yup.object().shape({
  name: yup.string().required('Name is required!'),
});

const MenuItemsCreate = () => {
  const { scopeFilteredMenus } = useDynamicMenu();
  const { reloadMenusData, selectedLocations, getSingleLocationOption } =
    useContext(MenuContext);
  const { menuOptions } = useOptions();
  const newRootMenuOrder =
    menuOptions.filter((obj) => !obj.rawObj?.parent_menu_item_id).length + 1;
  const { getMenuDetails, getAllMenus } = useActions();
  const { menuDetails } = useIndexData();
  const location = useLocation();
  const urlParams = getAllUrlParams(location?.search);
  const currentPath = useCurrentPath();
  const { onNavigate } = useOwnNavigate();
  const menuId = Number(urlParams.id || 0);
  const isEdit = !!menuId;
  const isViewOnly = isUrlAction(urlParams?.[VIEW_PARAM_KEY]);
  const isView = isViewOnly && isEdit;
  const isMenuLocked = menuDetails?.locked === 1;
  const isEditAndLocked = isEdit && isMenuLocked;
  const notificationAlertRef = useRef(null);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [detailsLoading, setDetailsLoading] = useState(false);
  const getTitle = () => {
    if (isView) return `View Details - ${menuDetails?.item_name || ''}`;
    return isEdit
      ? `Edit Menu - ${menuDetails?.item_name || ''}`
      : 'Create New Menu';
  };
  const getSubTitle = () => {
    if (isView) return menuDetails?.description;
    return (
      <>
        Lorem Ipsum is simply dummy text of the printing and typesetting
        industry. Lorem Ipsum
        <br />
        has been the industry's standard dummy text ever since the 1500s, when
        an unknown printer
        <br />
        took a galley of type and scrambled it to make a type specimen book.
      </>
    );
    // if (isView) return 'View Details sub title';
    // return isEdit ? 'Edit View sub title' : 'Create sub title';
  };
  const getBreadcrumbText = () => {
    if (isView) return 'Menu Item View Details';
    return isEdit ? 'Menu Item Edit' : 'Menu Item Create';
  };
  const breadcrumbs = [
    {
      link: '/admin/menu-items',
      label: 'Menu Items',
    },
    {
      label: getBreadcrumbText(),
    },
  ];
  const { handleSubmit, control, setValue, formState, reset } = useForm({
    resolver: yupResolver(formSchema),
    defaultValues: {
      parent_menu: NONE_OPTION,
      name: '',
      url: '',
      description: '',
      icon: '',
      type: MENU_ITEM_DEFAULT_TYPE_OPTION,
      locations: selectedLocations,
      order_index: newRootMenuOrder,
    },
  });
  const notify = (type, title, description) => {
    notifyPrimary({ ref: notificationAlertRef, type, title, description });
  };
  const reloadMenuDetails = (cb) => {
    setDetailsLoading(true);
    getMenuDetails(menuId, (res) => {
      setDetailsLoading(false);
      if (cb) cb(res);
    });
  };
  const onSubmit = async (values) => {
    if (!submitLoading) {
      setSubmitLoading(true);
      const tPayload = getCreateMenuPayload(values);
      let res = null;
      if (menuId) {
        tPayload.status = menuDetails.status;
        if (isEditAndLocked) {
          tPayload.location_ids = [];
        }
        res = await onEditMenuItem(menuId, tPayload);
      } else {
        tPayload.status = 'active';
        res = await onCreateMenuItem(tPayload);
      }
      setSubmitLoading(false);
      if (res.isSuccess) {
        reloadMenusData();
        onNavigate('/admin/menu-items');
      } else {
        notify(
          'error',
          'Error',
          `${menuId ? 'Edit' : 'Create'} Menu Item failed!`
        );
      }
    }
  };

  useEffect(() => {
    if (menuId) {
      reloadMenuDetails((res) => {
        if (!isView && !!isEdit) {
          const editObj = res?.data;
          if (editObj?.id) {
            reset({
              parent_menu: editObj.parent_menu_item_id
                ? {
                    value: editObj.parent_menu_item_id,
                    label: editObj.parent_menu_item_name,
                  }
                : NONE_OPTION,
              name: editObj.item_name,
              url: editObj.url,
              order_index: editObj.order_index || 0,
              icon: editObj.icon || '',
              description: editObj.description || '',
              type: editObj.item_type
                ? MENU_ITEM_TYPE_OPTIONS.find(
                    (obj) => obj.value === editObj.item_type
                  ) || MENU_ITEM_DEFAULT_TYPE_OPTION
                : MENU_ITEM_DEFAULT_TYPE_OPTION,
              locations: !editObj.location_ids?.length
                ? selectedLocations
                : editObj.location_ids
                    .map((id) => {
                      return getSingleLocationOption(id);
                    })
                    .filter((v) => !!v),
            });
          }
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [menuId]);
  useEffect(() => {
    if (!isViewOnly) {
      getAllMenus({});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <GenericCreateWrapper>
        {detailsLoading && <Loading isPage />}
        <Breadcrumb items={breadcrumbs} />
        <GenericCreateTitle subTitle={getSubTitle()}>
          <span>{getTitle()}</span>
        </GenericCreateTitle>
        <form
          role="form"
          className={classes.form}
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className={classes.formInner}>
            {isView ? (
              <>
                <Field
                  label="Parent Menu"
                  value={
                    menuDetails?.parent_menu_item_name || NONE_OPTION.label
                  }
                />
                <Field label="Name" value={menuDetails?.item_name} />
                <Field
                  label="Location"
                  value={
                    <>
                      {!menuDetails?.location_ids?.length && !isMenuLocked
                        ? '-'
                        : (isMenuLocked
                            ? scopeFilteredMenus
                            : menuDetails.location_ids.map((tId) =>
                                getSingleLocationOption(tId)
                              )
                          ).map((obj) => {
                            return <span key={obj.value}>{obj.label}</span>;
                          })}
                    </>
                  }
                />
                <Field label="URL" value={menuDetails?.url || '-'} />
                <Field label="Icon" value={menuDetails?.icon || '-'} />
                <Field
                  label="Description"
                  value={menuDetails?.description || '-'}
                />
              </>
            ) : (
              <>
                <FormGroup>
                  <FormSelect
                    options={[NONE_OPTION, ...menuOptions]}
                    label="Parent Menu"
                    name="parent_menu"
                    placeholder="Select parent menu"
                    control={control}
                    radius="md"
                    variant="primary"
                    fontSize="sm"
                    autoFocus
                    onChange={(val) => {
                      const tNewChildMenuOrder =
                        menuOptions.filter(
                          (obj) => obj.rawObj?.parent_menu_item_id === val.value
                        ).length + 1;
                      setValue(
                        'order_index',
                        val.value === NONE_OPTION.value
                          ? newRootMenuOrder
                          : tNewChildMenuOrder
                      );
                    }}
                  />
                </FormGroup>
                <FormGroup>
                  <FormInput
                    required
                    label="Name"
                    name="name"
                    placeholder="Enter name"
                    control={control}
                    radius="md"
                    variant="primary"
                    fontSize="sm"
                  />
                </FormGroup>
                {!isEditAndLocked && (
                  <FormGroup>
                    <SelectLocation
                      name="locations"
                      onChange={(val) => {
                        setValue('locations', val, { shouldDirty: true });
                      }}
                      control={control}
                      label="Locations"
                      required
                    />
                  </FormGroup>
                )}
                <FormGroup>
                  <FormSelect
                    options={MENU_ITEM_TYPE_OPTIONS}
                    label="Type"
                    name="type"
                    placeholder="Select type"
                    control={control}
                    radius="md"
                    variant="primary"
                    fontSize="sm"
                  />
                </FormGroup>
                <FormGroup>
                  <FormInput
                    label="Order"
                    name="order_index"
                    placeholder="Enter order"
                    control={control}
                    radius="md"
                    variant="primary"
                    fontSize="sm"
                    type="number"
                  />
                </FormGroup>
                <FormGroup>
                  <FormInput
                    label="Path"
                    name="url"
                    placeholder="Enter path"
                    control={control}
                    radius="md"
                    variant="primary"
                    fontSize="sm"
                  />
                </FormGroup>
                <FormGroup>
                  <FormInput
                    label="Icon"
                    name="icon"
                    placeholder="Enter icon"
                    control={control}
                    radius="md"
                    variant="primary"
                    fontSize="sm"
                  />
                </FormGroup>
                <FormGroup>
                  <FormInput
                    label="Description"
                    name="description"
                    placeholder="Enter description"
                    control={control}
                    type="textarea"
                    radius="md"
                    variant="primary"
                    fontSize="sm"
                  />
                </FormGroup>
              </>
            )}
          </div>
          {!isView && (
            <GenericCreateFooter>
              <Button
                variant="primary"
                isLink
                type="button"
                onClick={() => {
                  onNavigate('/admin/menu-items');
                }}
              >
                Cancel
              </Button>
              <Button
                variant="primary"
                type="submit"
                disabled={!formState.isValid || !formState.isDirty}
                loading={submitLoading}
              >
                {isEdit ? 'Save Changes' : 'Create'}
              </Button>
            </GenericCreateFooter>
          )}
        </form>
      </GenericCreateWrapper>
      <ToastAlert toastRef={notificationAlertRef} />
    </>
  );
};

export default MenuItemsCreate;
