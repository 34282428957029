import cn from 'classnames';
import FileItem from './FileItem';
import classes from './Upload.module.scss';

const UploadContent = ({ className, data, onDelete }) => {
  if (!data?.length) return '';
  return (
    <div className={cn(classes.contentFilesList, className)}>
      {data.map((item, i) => {
        return (
          <FileItem
            name={item.name}
            size={item.size}
            key={i}
            onDelete={() => {
              if (onDelete) onDelete(i);
            }}
          />
        );
      })}
    </div>
  );
};

export default UploadContent;
