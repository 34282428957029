import React, { createContext, useContext, useEffect, useState } from 'react';
import { LOCATIONS_SCOPE_ATTR_NAME } from 'src/helper/constants';
import useDynamicMenu from 'src/helper/hooks/useDynamicMenu';
import * as menuActions from 'src/store/actions/menu';
import * as sharedActions from 'src/store/actions/shared';
import { useReducerData, useStoreActions } from 'src/store/hooks';
import { applySearch } from 'src/utils/search';
import {
  getAllMenusFromLocations,
  getLocationOptionFromId,
  getSelectedLocationIds,
} from '../utils';

const useActions = () => {
  return useStoreActions({
    ...menuActions,
    ...sharedActions,
  });
};

const MenuContext = createContext({
  selectedLocations: [],
  availableMenus: [],
  selectedLocationIds: [],
  onUpdateSelectedLocations: () => {},
  reloadMenusData: () => {},
  getSingleLocationOption: () => {},
});

const MenuProvider = ({ children }) => {
  const { loadMenusData, syncUpdateScopeFilterLocations } = useActions();
  const isLoggedIn = !!useReducerData('auth', 'login.data.token', '');
  const preSelectedLocations = useReducerData(
    'shared',
    'scopeFilterLocations.data.selectedLocations',
    []
  );
  const { scopeFilteredMenus } = useDynamicMenu();
  const defaultSelectedLocations =
    preSelectedLocations.length > 0 ? preSelectedLocations : scopeFilteredMenus;
  const [selectedLocations, setSelectedLocations] = useState(
    defaultSelectedLocations
  );
  const availableMenus = getAllMenusFromLocations(selectedLocations);
  const selectedLocationIds = getSelectedLocationIds(selectedLocations);

  const reloadMenusDataWithVal = (ids) => {
    if (ids?.length) {
      loadMenusData({
        [LOCATIONS_SCOPE_ATTR_NAME]: ids.join(','),
      });
    }
  };
  const reloadMenusData = () => {
    reloadMenusDataWithVal(selectedLocationIds);
  };
  const getSingleLocationOption = (locationId) => {
    return getLocationOptionFromId(locationId, scopeFilteredMenus);
  };
  const onUpdateSelectedLocations = (val) => {
    setSelectedLocations(val);
    applySearch(val, (tVal) => {
      syncUpdateScopeFilterLocations(tVal);
      const tLocationIds = getSelectedLocationIds(tVal);
      reloadMenusDataWithVal(tLocationIds);
    });
  };

  useEffect(() => {
    if (isLoggedIn) {
      setSelectedLocations(defaultSelectedLocations);
      syncUpdateScopeFilterLocations(defaultSelectedLocations);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn]);
  useEffect(() => {
    if (isLoggedIn) {
      const tSelectedLocations = selectedLocations.filter(
        (obj) =>
          scopeFilteredMenus.findIndex((obj1) => obj1.value == obj.value) >= 0
      );
      setSelectedLocations(tSelectedLocations);
      applySearch(tSelectedLocations, (tVal) => {
        syncUpdateScopeFilterLocations(tVal);
        const tLocationIds = getSelectedLocationIds(tVal);
        reloadMenusDataWithVal(tLocationIds);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(scopeFilteredMenus)]);

  return (
    <MenuContext.Provider
      value={{
        selectedLocations,
        onUpdateSelectedLocations,
        availableMenus,
        selectedLocationIds,
        reloadMenusData,
        getSingleLocationOption,
      }}
    >
      {children}
    </MenuContext.Provider>
  );
};
export const useMenu = () => useContext(MenuContext);

export { MenuContext, MenuProvider };
