import { ReactComponent as WarningSvg } from 'src/assets/icons/warning-2.svg';
import Icon from 'src/components/Icon';
import { RESOURCE_DATA_KEY } from 'src/helper/constants/store';
import classes from './LocationDetails.module.scss';
import TabContent from './components/TabContent';

export const LOCATION_DETAILS_TAB_KEYS = {
  SUB_SYSTEMS: 'SUB_SYSTEMS',
  EQUIPMENT: 'EQUIPMENT',
  EMERGENCY: 'EMERGENCY',
  STAFF_LIST: 'STAFF_LIST',
  CHEMICALS: 'CHEMICALS',
};
export const LOCATION_DETAILS_TAB_SUB_KEYS = {
  CONTACTS: 'CONTACTS',
  PROCEDURES: 'PROCEDURES',
  EVACUATION_PLANS: 'EVACUATION_PLANS',
};
export const LOCATION_DETAILS_TABS = [
  {
    key: LOCATION_DETAILS_TAB_KEYS.SUB_SYSTEMS,
    title: 'Sub-Systems',
  },
  {
    key: LOCATION_DETAILS_TAB_KEYS.EQUIPMENT,
    title: 'Equipment',
  },
  {
    key: LOCATION_DETAILS_TAB_KEYS.EMERGENCY,
    title: 'Emergency',
  },
  {
    key: LOCATION_DETAILS_TAB_KEYS.STAFF_LIST,
    title: 'Staff List',
  },
  {
    key: LOCATION_DETAILS_TAB_KEYS.CHEMICALS,
    title: 'Chemicals',
  },
];
export const getContactActionMessage = (isEdit, isSuccess) => {
  return [
    isSuccess ? 'success' : 'error',
    isSuccess ? 'Success!' : 'Error!',
    isSuccess
      ? `Contact ${isEdit ? 'updated' : 'added'} successfully`
      : `${isEdit ? 'Update' : 'Add'} contact failed`,
  ];
};
export const getDeleteActionMessage = (type, isSuccess) => {
  return [
    isSuccess ? 'success' : 'error',
    isSuccess ? 'Success!' : 'Error!',
    isSuccess
      ? type === RESOURCE_DATA_KEY.EMERGENCY_CONTACT
        ? 'Contact removed successfully'
        : 'Staff archival request submitted successfully'
      : type === RESOURCE_DATA_KEY.EMERGENCY_CONTACT
      ? 'Remove contact failed'
      : 'Remove staff failed',
  ];
};
export const getAddPlanMessage = (isSuccess) => {
  return [
    isSuccess ? 'success' : 'error',
    isSuccess ? 'Success!' : 'Error!',
    isSuccess ? 'Documents added successfully' : 'Add documents failed',
  ];
};
export const getArchiveDocumentMessage = () => {
  return ['success', 'Success', 'Document archived successfully'];
};
export const getAddedStaffMessage = (isEdit) => {
  return [
    'success',
    'Success',
    `Staff ${isEdit ? 'edited' : 'added'} successfully`,
  ];
};
export const getDeleteContactObj = (id) => {
  return {
    title: 'Remove Emergency Contact',
    description:
      'Are you sure you want to remove this emergency contact? This action cannot be undone.',
    submitText: 'Remove Contact',
    id,
    type: RESOURCE_DATA_KEY.EMERGENCY_CONTACT,
  };
};
export const getDeleteStaffObj = (id) => {
  return {
    title: 'Remove Staff from Location',
    description:
      'Please confirm that you want to remove this Staff member from this location. They can be added back at anytime.',
    submitText: 'Remove Staff',
    id,
    type: RESOURCE_DATA_KEY.LOCATIONS,
  };
};
export const getTabItems = (isEmergencyWarning, tabProps) => {
  return LOCATION_DETAILS_TABS.map((obj) => {
    return {
      key: obj.key,
      title: (
        <>
          {obj.title}
          {obj.key === LOCATION_DETAILS_TAB_KEYS.EMERGENCY &&
            isEmergencyWarning && (
              <Icon
                icon={<WarningSvg />}
                activeIcon={<WarningSvg />}
                size={20}
                className={classes.warningIcon}
              />
            )}
        </>
      ),
      content: <TabContent tabKey={obj.key} {...tabProps} />,
    };
  });
};
export const LOCATION_TAB_DEFAULT_FILTERS = {
  search: '',
  sort: {
    field: '',
    method: '',
  },
  filters_id: [],
  pagination: {
    page: 1,
    per_page: 10,
  },
};
