import cn from 'classnames';
import React from 'react';
import { ReactComponent as TrashSvg } from 'src/assets/icons/trash.svg';
import Icon from 'src/components/Icon';
import { formatFileSize } from 'src/utils/number';
import classes from './Upload.module.scss';

const FileItem = ({ className, name, size, onDelete }) => {
  return (
    <div className={cn(classes.fileItem, className)}>
      <div>
        <span>{name || ''}</span>
        <span>{formatFileSize(size)}</span>
      </div>
      <Icon
        icon={<TrashSvg />}
        activeIcon={<TrashSvg />}
        onClick={onDelete}
        size={18}
      />
    </div>
  );
};

export default FileItem;
