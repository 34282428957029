import cn from 'classnames';
import { useState } from 'react';
import Button from 'src/components/Button';
import Filter from 'src/components/Filter';
import SearchInput from 'src/components/FormFields/Input/SearchInput';
import Pagination from 'src/components/Pagination';
import Table from 'src/components/Table';
import { applySearch } from 'src/utils/search';
import classes from './Table.module.scss';

const GenericTable = ({
  className,
  columns,
  data,
  title,
  searchPlaceholder,
  onAdd,
  onPaginationChange,
  onSearchChange,
  onSortChange,
  total,
  loading,
  hideCheckboxes,
  hideHeader,
  hidePagination,
  hoverable,
  addBtnText,
  paginationSettings,
  onCheckChanged,
  checkedRows,
}) => {
  const tData = data || [];
  const allIds = tData.map((obj) => obj.id);
  const [size, setSize] = useState(10);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState('');
  const [sort, setSort] = useState({
    field: '',
    method: '',
  });
  return (
    <div
      className={cn(
        classes.wrapper,
        {
          [classes.unbox]: !!hideHeader,
        },
        className
      )}
    >
      {!hideHeader && (
        <div className={classes.header}>
          <span>{title}</span>
          <div>
            <SearchInput
              size="medium"
              placeholder={searchPlaceholder || 'Search'}
              iconPosition="right"
              value={search}
              onChange={(e) => {
                setSearch(e.target.value);
                applySearch(e.target.value, (val) => {
                  if (onSearchChange) {
                    setPage(1);
                    onSearchChange(val);
                  }
                });
              }}
            />
            <Filter />
            <Button
              variant="primary"
              onClick={onAdd}
              fontSize="sm"
              size="medium"
            >
              {addBtnText || 'Add New'}
            </Button>
          </div>
        </div>
      )}
      <Table
        columns={columns}
        data={tData}
        checkable={
          hideCheckboxes
            ? false
            : {
                checkedRows: (checkedRows || []).filter((obj) =>
                  allIds.includes(obj.id)
                ),
                onChange: (...params) => {
                  if (onCheckChanged) onCheckChanged(...params);
                },
              }
        }
        sort={sort}
        onSortChange={(tField, tMethod) => {
          const newSort = {
            field: tField,
            method: tMethod,
          };
          setSort(newSort);
          if (onSortChange) {
            setPage(1);
            onSortChange(newSort);
          }
        }}
        loading={loading}
        hoverable={hoverable}
      />
      {!hidePagination && (
        <Pagination
          data={{ size, page, total: total || 0 }}
          onChange={(tPage, tSize) => {
            setPage(tPage);
            setSize(tSize);
            if (onPaginationChange && (tPage !== page || tSize !== size))
              onPaginationChange(tPage, tSize);
          }}
          disabled={loading}
          {...(paginationSettings || {})}
        />
      )}
    </div>
  );
};

export default GenericTable;
