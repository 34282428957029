import cn from 'classnames';
import { ReactComponent as ArchiveSvg } from 'src/assets/icons/archive.svg';
import { ReactComponent as DocumentPreviousSvg } from 'src/assets/icons/document-previous.svg';
import { ReactComponent as ExportSvg } from 'src/assets/icons/export.svg';
import Icon from 'src/components/Icon';
import Tag from 'src/components/Tag';
import Tooltip from 'src/components/Tooltip';
import classes from './Info.module.scss';

const Info = ({ className, onArchive, onViewHistory, onUpload, data }) => {
  return (
    <div className={cn(classes.wrapper, className)}>
      <div className={cn(classes.item)}>
        <span>Uploaded by</span>
        <span>{data?.uploaded_by}</span>
      </div>
      <div className={cn(classes.item)}>
        <span>Uploaded date</span>
        <span>{data?.uploaded_date}</span>
      </div>
      <div className={cn(classes.item)}>
        <span>Status</span>
        {!!data?.status && (
          <span>
            <Tag variant={data?.status?.type}>{data?.status?.text}</Tag>
          </span>
        )}
      </div>
      <div className={classes.actions}>
        <Tooltip
          placement="bottom"
          tooltipText="Upload New Version"
          dark
          hasArrow
        >
          <span className={classes.iconWrapper} onClick={onUpload}>
            <Icon icon={<ExportSvg />} activeIcon={<ExportSvg />} size={20} />
          </span>
        </Tooltip>
        <Tooltip
          placement="bottom"
          tooltipText="View Document History"
          dark
          hasArrow
        >
          <span className={classes.iconWrapper} onClick={onViewHistory}>
            <Icon
              icon={<DocumentPreviousSvg />}
              activeIcon={<DocumentPreviousSvg />}
              size={20}
            />
          </span>
        </Tooltip>
        <Tooltip
          placement="bottom"
          tooltipText="Archive Document"
          dark
          hasArrow
        >
          <span className={classes.iconWrapper} onClick={onArchive}>
            <Icon icon={<ArchiveSvg />} activeIcon={<ArchiveSvg />} size={20} />
          </span>
        </Tooltip>
      </div>
    </div>
  );
};

export default Info;
