import cn from 'classnames';
import NotificationAlert from 'react-notification-alert';

const ToastAlert = ({ toastRef, className }) => {
  return (
    <>
      <div className={cn('rna-wrapper primary', className)}>
        <NotificationAlert ref={toastRef} />
      </div>
    </>
  );
};

export default ToastAlert;
