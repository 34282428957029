import cn from 'classnames';
import {
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from 'reactstrap';
import classes from './Input.module.scss';

const SearchInput = ({
  className,
  size,
  placeholder,
  iconPosition,
  value,
  onChange,
  light,
}) => {
  const isIconRight = iconPosition === 'right';

  const iconEl = (
    <InputGroupAddon addonType="prepend">
      <InputGroupText>
        <i className="fas fa-search" />
      </InputGroupText>
    </InputGroupAddon>
  );
  return (
    <FormGroup
      className={cn(
        classes.searchInputWrapper,
        classes[`size_${size}`],
        {
          [classes.isIconRight]: isIconRight,
          [classes.isLight]: light,
        },
        className,
        'mb-0'
      )}
    >
      <InputGroup className="input-group-alternative input-group-merge">
        {!isIconRight && iconEl}
        <Input
          placeholder={placeholder}
          type="text"
          value={value}
          onChange={onChange}
        />
        {isIconRight && iconEl}
      </InputGroup>
    </FormGroup>
  );
};

export default SearchInput;
