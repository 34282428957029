import cn from 'classnames';
import React, { useRef, useState } from 'react';
import { Popover } from 'reactstrap';
import { ReactComponent as CloseCircle1Svg } from 'src/assets/icons/close-circle1.svg';
import { ReactComponent as FilterSvg } from 'src/assets/icons/filter.svg';
import Button from 'src/components/Button';
import Checkbox from 'src/components/FormFields/Checkbox';
import Icon from 'src/components/Icon';
import useClickOutside from 'src/helper/hooks/useClickOutside';
import classes from './Filter.module.scss';

const Filter = ({
  className,
  items,
  overlayClassName,
  selectedItemIds,
  onChange,
}) => {
  const tRef = useRef(null);
  const popoverRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [filtersChecked, setFiltersChecked] = useState([]);
  const tempCheckedHasItems = filtersChecked.length > 0;
  const tSelectedItemIds = selectedItemIds || [];
  const selectedItemsLength = tSelectedItemIds.length;
  const hasSelectedItems = selectedItemsLength > 0;
  const tItems = items || [];
  const toggle = () => {
    setIsOpen((open) => !open);
  };
  const handleChange = (parentId, id, tChecked) => {
    setFiltersChecked(
      filtersChecked.includes(id)
        ? filtersChecked.filter((v) => v !== id)
        : [...filtersChecked, id]
    );
  };
  const renderItem = (tItem, isRoot, tParentId) => {
    if (tItem?.items?.length > 0) {
      return (
        <div className={classes.items}>
          <span className={classes.parentLabel}>
            <span>{tItem.label}</span>
            {isRoot && (
              <span
                onClick={() => {
                  if (tempCheckedHasItems) {
                    setFiltersChecked([]);
                  } else {
                    setFiltersChecked(
                      items.flatMap((obj) => obj.items.map((sObj) => sObj.id))
                    );
                  }
                }}
              >
                {tempCheckedHasItems ? 'Deselect' : 'Select'} All
              </span>
            )}
          </span>
          {tItem.items.map((subItem) => {
            return (
              <React.Fragment key={subItem.id}>
                {renderItem(subItem, false, tItem.id)}
              </React.Fragment>
            );
          })}
        </div>
      );
    }
    return (
      <div
        onClick={() => {
          if (tItem.onClick) tItem.onClick(tItem);
        }}
        className={classes.filterItem}
      >
        <Checkbox
          variant="primary"
          id={`filter-item-${tItem.id}`}
          className={classes.checkbox}
          checked={filtersChecked.includes(tItem.id)}
          onChange={(e) => {
            handleChange(tParentId, tItem.id, e.target.checked);
          }}
          label={tItem.label}
          fontSize="md"
        />
      </div>
    );
  };
  useClickOutside(popoverRef, () => {
    setIsOpen(false);
  });

  return (
    <>
      <button
        className={cn(classes.wrapper, className)}
        onClick={toggle}
        ref={tRef}
      >
        <Icon size={20} icon={<FilterSvg />} activeIcon={<FilterSvg />} />
        <span>Filter{hasSelectedItems ? ` (${selectedItemsLength})` : ''}</span>
        {hasSelectedItems && (
          <Icon
            size={20}
            icon={<CloseCircle1Svg />}
            activeIcon={<CloseCircle1Svg />}
            onClick={(e) => {
              setFiltersChecked([]);
              if (onChange) onChange([]);
              setTimeout(() => {
                setIsOpen(false);
              }, 100);
              e.preventDefault();
              e.stopPropagation();
            }}
          />
        )}
      </button>
      <Popover
        placement={'bottom'}
        target={tRef}
        isOpen={isOpen && !!tItems.length}
        toggle={toggle}
        innerRef={popoverRef}
        className={cn(classes.popoverOverlay, overlayClassName, {
          [classes.hideDropdown]: !tItems.length,
        })}
      >
        <div className={classes.items}>
          {tItems.map((item, i) => {
            return (
              <React.Fragment key={item.id}>
                {renderItem(item, item.items?.length > 0 && i === 0, item.id)}
              </React.Fragment>
            );
          })}
        </div>
        <Button
          variant="primary"
          onClick={() => {
            if (onChange) onChange(filtersChecked);
            setIsOpen(false);
          }}
          size="medium"
          weight="lg"
          fontSize="sm"
          fullWidth
          disabled={String(selectedItemIds) === String(filtersChecked)}
        >
          Apply Filters
        </Button>
      </Popover>
    </>
  );
};

export default Filter;
