import { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Breadcrumb from 'src/components/Breadcrumb';
import Loading from 'src/components/Loading';
import ConfirmModal from 'src/components/Modal/Confirm';
import PageTitle from 'src/components/Page/PageTitle';
import PageWrapper from 'src/components/Page/PageWrapper';
import ToastAlert from 'src/components/ToastAlert';
import { RESOURCE_DATA_KEY } from 'src/helper/constants/store';
import useOwnNavigate from 'src/helper/hooks/useOwnNavigate';
import useResourceActions from 'src/helper/hooks/useResourceActions';
import AddPlanModal from 'src/pages/LocationDetails/components/AddPlanModal';
import { LOCATION_DETAILS_TAB_KEYS } from 'src/pages/LocationDetails/constants';
import { getFormatDate } from 'src/utils/date';
import { notifyPrimary } from 'src/utils/notification';
import { getPlanStatusByTime } from 'src/utils/plan';
import { getArchiveUrlAction, getTabUrlAction } from 'src/utils/routes';
import classes from './LocationEmergencyDetails.module.scss';
import FilePreview from './components/FilePreview';
import HistoryModal from './components/HistoryModal';
import Info from './components/Info';
import { useActions, useIndexData } from './selectorData';

const LocationEmergencyDetails = () => {
  const { onArchiveResource } = useResourceActions();
  const notificationAlertRef = useRef(null);
  const params = useParams();
  const { onNavigate } = useOwnNavigate();
  const locationId = Number(params.id || 0);
  const emergencyId = Number(params.emergencyId || 0);
  const [archiveId, setArchiveId] = useState(null);
  const [viewHistory, setViewHistory] = useState(false);
  const [modalPlanObj, setModalPlanObj] = useState(false);
  const [locationDetailsLoading, setLocationDetailsLoading] = useState(false);
  const [documentDetailsLoading, setDocumentDetailsLoading] = useState(false);
  const { locationDetails, documentDetails } = useIndexData();
  const { getLocationDetails, getResourceDetails } = useActions();
  const breadcrumbs = [
    {
      link: `/locations`,
      label: 'Locations',
    },
    {
      link: `/locations/${locationId}`,
      label: locationDetails?.name,
    },
    {
      label: documentDetails?.title,
    },
  ];
  const notify = (type, title, description) => {
    notifyPrimary({ ref: notificationAlertRef, type, title, description });
  };
  const handleConfirmedDelete = (obj, loadingCb, cb) => {
    loadingCb();
    cb();
  };
  const handleConfirmedView = (obj) => {
    setViewHistory(null);
  };
  const reloadDocumentDetails = (cb) => {
    setDocumentDetailsLoading(true);
    getResourceDetails(
      RESOURCE_DATA_KEY.EMERGENCY_DOCUMENT,
      emergencyId,
      (res) => {
        setDocumentDetailsLoading(false);
        if (cb) cb(res);
      }
    );
  };
  const reloadLocationDetails = (cb) => {
    setLocationDetailsLoading(true);
    getLocationDetails(locationId, (res) => {
      setLocationDetailsLoading(false);
      if (cb) cb(res);
    });
  };
  const handleConfirmedArchiveFile = async (cb) => {
    const res = await onArchiveResource(
      RESOURCE_DATA_KEY.EMERGENCY_DOCUMENT,
      archiveId,
      {},
      'PUT'
    );
    if (cb) cb();
    if (res.isSuccess) {
      onNavigate(
        getArchiveUrlAction(
          getTabUrlAction(
            `/locations/${locationId}`,
            LOCATION_DETAILS_TAB_KEYS.EMERGENCY
          )
        )
      );
    } else {
      notify('error', 'Error!', 'Archive document failed');
    }
  };

  useEffect(() => {
    reloadLocationDetails();
    reloadDocumentDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <PageWrapper className={classes.wrapper}>
        {(locationDetailsLoading || documentDetailsLoading) && (
          <Loading isPage />
        )}
        <Breadcrumb items={breadcrumbs} />
        <div className={classes.pageTitleWrapper}>
          <PageTitle>{documentDetails?.title}</PageTitle>
          <Info
            onArchive={() => {
              setArchiveId(emergencyId);
            }}
            onViewHistory={() => {
              setViewHistory(true);
            }}
            onUpload={() => {
              setModalPlanObj({});
            }}
            data={{
              uploaded_by: documentDetails?.uploaded_by_name,
              uploaded_date: getFormatDate(
                documentDetails?.created_at,
                'MMM D, YYYY'
              ),
              status: getPlanStatusByTime(documentDetails.valid_until),
            }}
          />
        </div>
        <FilePreview
          className={classes.filePreview}
          title={documentDetails?.title}
          url={documentDetails?.url}
        />
      </PageWrapper>
      {!!archiveId && (
        <ConfirmModal
          isOpen
          onClose={() => {
            setArchiveId(null);
          }}
          onSubmit={handleConfirmedArchiveFile}
          title="Archive file"
          submitText="Archive File"
          size="sm"
          fontSize="sm"
        >
          Are you sure you want to archive this file? It will no longer be
          publicly available for audits.
        </ConfirmModal>
      )}
      {!!viewHistory && (
        <HistoryModal
          isOpen
          onClose={() => {
            setViewHistory(false);
          }}
          onConfirmedDelete={handleConfirmedDelete}
          onConfirmedView={handleConfirmedView}
        />
      )}
      {!!modalPlanObj && (
        <AddPlanModal
          isOpen
          onClose={() => {
            setModalPlanObj(null);
          }}
          onSubmit={(values) => {
            setModalPlanObj(null);
          }}
          obj={modalPlanObj}
          title="Upload New Version"
          description="Since you are updating the version of an existing file, you can select and upload only one file."
          isSingle
        />
      )}
      <ToastAlert toastRef={notificationAlertRef} />
    </>
  );
};

export default LocationEmergencyDetails;
